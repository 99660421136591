import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Equals, NotEquals } from '../../js/calculationHelpers';
import { IsTrue, NotEmpty, PreventDefault } from '../../js/formHelpers';

import { get } from '../../services/apiService';

//import { PaymentForm } from '../Payment/PaymentForm';
import { PaymentModal } from '../Payment/_modals/PaymentModal.js';

export const PaymentModalContent = ({ close, invoices, confirmPayment }) => {
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
    const [individualPayments, setIndividualPayments] = useState({});

    const [depositRegisters, setDepositRegisters] = useState([]);
    const [selectedDepositRegister, setSelectedDepositRegister] = useState('');

    const [paymentType, setPaymentType] = useState('');
    const [paymentTypes, setPaymentTypes] = useState([]);

    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [paymentData, setPaymentData] = useState(null);
        
    //const navigate = useNavigate();

    const initialPayments = useMemo(() => {
        if (!invoices || invoices.length === 0) return {};
        return invoices.reduce((acc, invoice) => {
            acc[invoice.invoiceId] = invoice.invoiceTotal.toFixed(2);
            return acc;
        }, {});
    }, [invoices]);

    const fetchDepositRegisters = useCallback(async () => {
        try {
            const response = await get('depositregisters/GetDepositRegisters');
            setDepositRegisters(Array.isArray(response) ? response : response?.$values || []);
        } catch (error) {
            console.error('Error fetching deposit registers:', error);
        }
    }, []);

    const fetchPaymentTypes = useCallback(async () => {
        try {
            const response = await get('payments/GetPaymentTypes');
            const types = Array.isArray(response) ? response : response?.$values || [];

            var paymentTypes = [];
            if (NotEmpty(types)) {
                types.forEach((item, index) => {
                    if (Equals(item.name, "Cash") || Equals(item.name, "Card") || Equals(item.name, "Check")) {
                        paymentTypes.push(item);
                    }
                });
            }
            setPaymentTypes(paymentTypes);
            setPaymentType("");

        } catch (error) {
            console.error('Error fetching payment types:', error);
        }
    }, []);

    const updateTotalPaymentAmount = useCallback((payments) => {
        const total = Object.values(payments).reduce((sum, amount) => sum + parseFloat(amount) || 0, 0);
        setTotalPaymentAmount(total.toFixed(2));
    }, []);

    const handleIndividualPaymentChange = useCallback((invoiceId, amount) => {
        const newAmount = parseFloat(amount).toFixed(2) || 0;
        setIndividualPayments(prev => {
            const updated = { ...prev, [invoiceId]: newAmount };
            updateTotalPaymentAmount(updated);
            return updated;
        });
    }, [updateTotalPaymentAmount]);

    const handleContinue = useCallback(async () => {
        try {
            const invoiceIds = invoices.map(invoice => invoice.invoiceId).join(',');
            const totals = await get(`invoice/CalculateTotalFromInvoices?invoiceIds=${invoiceIds}`);

            const payments = invoices.map(invoice => ({
                InvoiceId: invoice.invoiceId,
                PaymentAmount: (parseFloat(individualPayments[invoice.invoiceId]) || 0).toFixed(2),
                DepositRegisterId: parseInt(selectedDepositRegister),
                PaymentType: paymentType,
                BalanceDue: totals.toFixed(2),
                PaymentTypeId: parseInt(paymentType),
            }));

            const paymentsData = {
                payments: JSON.stringify(payments),
                totalPaymentAmount: totalPaymentAmount,
                balanceDue: JSON.stringify(totals),
                selectedDepositRegister,
                paymentType,
                orderInfo: JSON.stringify(totals),
                totalDue: totalPaymentAmount
            };

            setPaymentData(paymentsData);
            setShowPaymentForm(true);
        } catch (error) {
            console.error('Error calculating totals:', error);
        }
    }, [invoices, individualPayments, selectedDepositRegister, paymentType, totalPaymentAmount]);

    const onClose = useCallback((e) => {
        PreventDefault(e);
        close();
    }, []); 

    const onSubmitClosePayment = useCallback((msg) => {
        close(msg);
    }, []);

    const handleBackFromPaymentForm = useCallback(() => {
        setShowPaymentForm(false)
    } , []);

    //const handleSubmit = useCallback(() => {
    //    sessionStorage.setItem('paymentData', JSON.stringify(paymentData));
    //    navigate('/Payment');
    //    close();
    //}, [paymentData, navigate, close]);

    useEffect(() => {
        fetchDepositRegisters();
        fetchPaymentTypes();

        if (Object.keys(initialPayments).length > 0) {
            setIndividualPayments(initialPayments);
            updateTotalPaymentAmount(initialPayments);
        }
    }, []);

    if (showPaymentForm) {
        sessionStorage.setItem('paymentData', JSON.stringify(paymentData));
        return (
            <div className="modal-wrapper">
                <div className="modal-content !max-w-[1000px]">
                    <PaymentModal onClose={handleBackFromPaymentForm} submitClose={onSubmitClosePayment} />
                </div>
            </div>
        );
    }

    return (
        <div className="modal-wrapper">
            <div className="modal-content">
                <h2 className="text-2xl font-bold mb-4 text-center underline">Apply Payment</h2>
                <p className="mb-2 text-center"><strong>Account Name:</strong> {invoices[0]?.accountName}</p>
                <p className="mb-4 text-center"><strong>Number of Invoices:</strong> {invoices.length}</p>

                <div className="form-label pb-4">
                    <label className="label-form table-cell pr-2 mb-1">Total Payment Amount:</label>
                    <label className="input-group">
                        <span>$</span>
                        <input type="number" name="totalPaymentAmount" id="totalPaymentAmount"
                            className="label-form-input table-cell !mt-0"
                            value={totalPaymentAmount}
                            onChange={(e) => setTotalPaymentAmount(parseFloat(e.target.value) || 0)}
                            required
                        /> 
                    </label>
                </div>

                <div className="mb-4">
                    <label className="label-form mb-1">Individual Invoice Payments:</label>
                    {invoices.map(invoice => (
                        <div key={invoice.invoiceId} className="table-row">
                            <label className="table-cell pr-2">#{invoice.invoiceNumber}:</label>
                            <div className="table-cell pb-2">
                                <label className="input-group">
                                    <span>$</span>
                                    <input className="label-form-input !mt-0"
                                        type="number"
                                        value={individualPayments[invoice.invoiceId]}
                                        onChange={(e) => handleIndividualPaymentChange(invoice.invoiceId, e.target.value)}                                
                                        />
                                </label>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mb-4">
                    <label className="label-form mb-1">Payment Type:</label>
                    <select
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        className="label-form-input"
                    >
                        <option value="" selected>-- Select --</option>
                        {paymentTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="label-form mb-1">Deposit Register:</label>
                    <select
                        value={selectedDepositRegister}
                        onChange={(e) => setSelectedDepositRegister(e.target.value)}
                        className="label-form-input"
                    >
                        <option value="">-- Select --</option>
                        {depositRegisters.map((register) => (
                            <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                {register.registerName}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex justify-end mt-4">
                    <button onClick={e => onClose(e)} className="btn-cancel">Cancel</button>
                    <button onClick={handleContinue} className="btn-submit">Continue</button>
                </div>
            </div>
        </div>
    );
};