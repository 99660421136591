import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Exists, GetDisplayCurrencyStr, GetDisplayDateStr, NotStringEmpty } from '../../js/formHelpers';
import { Equals } from '../../js/calculationHelpers';

export const PaymentInvoiceDetailsModal = ({ paymentData, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const [columnDefs] = useState([
        { field: 'invoiceId', headerName: 'Invoice Id', maxWidth: 100 },
        { field: 'invoiceNumber', headerName: 'Invoice Number', maxWidth: 150 },
        { field: 'accountName', headerName: 'Account Name' },
        {
            field: 'amountPaid', headerName: 'Amount Paid',
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
    ]);

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            enableRangeSelection: true,
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    const invoicePayments = useMemo(() => {
        if (Array.isArray(paymentData.invoicePayments)) {
            return paymentData.invoicePayments.map(ip => ({
                invoiceNumber: ip.invoice.invoiceNumber,
                accountName: ip.invoice.accountName,
                amountPaid: ip.amountPaid,
                invoiceId: ip.invoiceId
            }));
        }
        console.warn('invoicePayments is not an array or is undefined');
        return [];
    }, [paymentData]);

    const filteredInvoicePayments = useMemo(() => {
        return invoicePayments.filter(payment =>
            Object.values(payment).some(value =>
                value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [invoicePayments, searchTerm]);

    return (
        <div className="modal-wrapper">
            <div className="modal-content !max-w-[1000px]">
                <div className="px-2">
                    <h3 className="text-center text-xl font-bold underline">Payment Details</h3>
                    <div className="mt-4">
                        <div className="mb-4">
                            <p className="mb-1">
                                <strong className="pr-1">Payment ID:</strong>
                                {paymentData.paymentId}
                            </p>
                            <p className="mb-1">
                                <strong className="pr-1">Payment Date:</strong>
                                {GetDisplayDateStr(paymentData.paymentDate)}
                            </p>
                            <p className="mb-1">
                                <strong className="pr-1">Total Payment:</strong>
                                {GetDisplayCurrencyStr(paymentData.amount)}
                            </p>
                            <p className="mb-1">
                                <strong className="pr-1">Payment Type:</strong>
                                {paymentData.paymentType}
                            </p>
                            {NotStringEmpty(paymentData.checkNumber) &&
                                <p className="mb-1">
                                    <strong className="pr-1">Check Number:</strong>
                                    {paymentData.checkNumber}
                                </p>
                            }
                            {NotStringEmpty(paymentData.identifier) && Equals(paymentData.paymentType, "Card") &&
                                <p className="mb-1">
                                    <strong className="pr-1">Card Last 4:</strong>
                                    {paymentData.identifier}
                                </p>
                            }
                        </div>
                        <h4 className="text-center text-xl font-bold mb-2 underline">Invoices</h4>
                        <input
                            type="text" placeholder="Filter..." className="grid-filter !w-[300px]"
                            value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="ag-theme-alpine ag-grid-act w-full" style={{ height: '300px' }}>
                            <AgGridReact
                                rowData={filteredInvoicePayments}
                                columnDefs={columnDefs}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                    <div className="text-right pt-2">
                        <button onClick={onClose} className="btn-close-modal">Close</button>
                        {/*<button disabled className="btn-submit-modal">Print Receipt</button>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};