import React, { useState, useEffect, useMemo } from 'react';

import { get } from '../../services/apiService';

import { SetPageTitle, GetValueById } from '../../js/helpers.js';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import {
    AgCurrencyMxWd, AgDateMxWd, defaultErrMsg, GetDisplayCurrencyStr,
    GetDisplayDateStr, GetDisplayDecimalStr, IsTrue
}
from '../../js/formHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import { ShowHideLoading } from '../_reactHelpers/ReactHtmlHelpers';

export function ReadyForPayment() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Ready for Payment");

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Methods

    const fetchBillsReport = async () => {
        setLoading(true);

        try {
            const response = await get(`bills/ReadyForPayment`);
            setRowData(response.bills ?? []);

        } catch (error) {
            console.error('Error fetching bills report:', error);
            alert(defaultErrMsg());
        } finally {
            //setLoading(false);
        }
    }

    const columnDefs = useMemo(() => [
        {
            headerName: '', maxWidth: 50, headerCheckboxSelection: true, checkboxSelection: true,
        },
        {
            headerName: 'Vendor', field: 'vendorName',
        },
        {
            headerName: 'Vendor Type', field: 'vendorTypeDesc',
        },
        {
            headerName: 'CC PO #', field: '',
        },
        {
            headerName: 'PO #', field: 'poNumber',
        },
        {
            headerName: 'Bill Date', field: 'billDate', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Due Date', field: 'dueDate', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Bill Total', field: 'billTotal', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Disc Rate', field: 'discRate', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayDecimalStr(params.value)
        },
        {
            headerName: 'Disc Amt', field: 'discAmt', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Non Disc Amt', field: 'nonDiscAmt', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Amount Due', field: 'amountDue', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Date Created', field: 'createdDate', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    useEffect(() => {
        SetPageTitle("Ready for Payment");
        fetchBillsReport();
    }, []);

    if (loading) {
        <ShowHideLoading show={loading}></ShowHideLoading>
    }

    return (
        <div className="page-wrapper">
            <div className="ag-theme-alpine ag-grid-act w-full pt-2" style={{ height: '600px' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={30}
                    rowSelection={'multiple'}
                    rowMultiSelectWithClick={true}
                />
            </div>
        </div>
    );
}