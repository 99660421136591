import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { get, post } from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';

import BankRegisterForm from './BankRegisterForm';
import EditBankRegisterForm from './EditBankRegisterForm';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { GetDisplayDateStr, IsTrue } from '../../js/formHelpers';

export const BankRegisters = () => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Transactions");

    const [bankRegisters, setBankRegisters] = useState([]);
    const [bank, setBank] = useState({});
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentRegister, setCurrentRegister] = useState(null);
    const [loading, setLoading] = useState(true);
    const [gridApi, setGridApi] = useState(null);

    const { bankId } = useParams();

    const fetchBank = async () => {
        try {
            const respBank = await get(`/bankaccount/getBankAccountById/${bankId}`);
            //console.log('Bank:', respBank);
            setBank(respBank);

            SetPageTitle("Transactions - " + respBank?.bankName ?? "");
        } catch (error) {
            console.error('Error fetching bank data:', error);
            setBank({});
        }
    };

    const fetchBankRegisters = async () => {
        try {
            const response = await get(`bankregister/GetRegisters/${bankId}`);
            const data = Array.isArray(response) ? response : response.$values || [];
            //console.log('Bank Registers:', data);

            setBankRegisters(data.map(item => ({
                ...item,
                id: item.registerId
            })));
            
            setLoading(false);
        } catch (error) {
            console.error('Error fetching bank registers:', error);
            setBankRegisters([]);
            setLoading(false);
        }
    };

    const openAddModal = () => setAddModalOpen(true);
    const closeAddModal = () => setAddModalOpen(false);
    const openEditModal = (register) => {
        setCurrentRegister(register);
        setEditModalOpen(true);
    };
    const closeEditModal = () => {
        setCurrentRegister(null);
        setEditModalOpen(false);
    };

    const handleAddBankRegister = () => {
        closeAddModal();
        fetchBankRegisters();
    };

    const handleBankRegisterUpdated = () => {
        closeEditModal();
        fetchBankRegisters();
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridApi.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, [gridApi]);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const columnDefs = useMemo(() => [
        { field: 'checkNumber', headerName: 'Check #', maxWidth: 100, },
        {
            field: 'date',
            headerName: 'Date',
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        { field: 'payee', headerName: 'Payee' },
        { field: 'description', headerName: 'Description' },
        {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: params => `${params.data.transactionType === 'deposit' ? '' : '-'} $${params.value.toFixed(2)}`
        },
        {
            field: 'transactionType',
            headerName: 'Type',
            cellRenderer: params => (<span className="capitalize">{params.value}</span>),
        },
        {
            field: 'balance',
            headerName: 'Balance',
            valueFormatter: params => `$${params.value.toFixed(2)}`
        },
        {
            headerName: '', maxWidth: 70,
            cellRenderer: params => (
                <div className="flex justify-center items-center h-full">
                    <button onClick={() => openEditModal(params.data)} className="btn-search">
                        Edit
                    </button>
                </div>
            ),
        },
    ], []);


    useEffect(() => {       

        fetchBank();
        fetchBankRegisters();

    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div className="page-wrapper">                        
            <div className="flex-wrapper mb-2">                
                <div className="flex">
                    <a href="\BankAccounts" className='btn-search !ml-0 pl-2'>
                        Go Back
                    </a>
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        id="filter-text-box"
                        className="grid-filter"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                    />
                </div>
                <button onClick={openAddModal} className="btn-small">
                    Post Deposit
                </button>                
            </div>

            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={bankRegisters}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                    domLayout='autoHeight'
                />
            </div>

            {isAddModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <BankRegisterForm
                            bankId={bankId}
                            onBankRegisterAdded={handleAddBankRegister}
                            onClose={closeAddModal}
                        />
                    </div>
                </div>
            )}

            {isEditModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <EditBankRegisterForm
                            bankRegisterData={currentRegister}
                            onBankRegisterUpdated={handleBankRegisterUpdated}
                            onClose={closeEditModal}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
