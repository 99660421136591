import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { get } from '../../services/apiService';
import { ShowHideError, ShowHideLoading } from '../_reactHelpers/ReactHtmlHelpers';
import { GetDisplayCurrencyStr, GetDisplayDateStr } from '../../js/formHelpers';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';

export const AgingInvoiceReport = () => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Aging Invoice Report");
    const [days, setDays] = useState(1);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const gridRef = useRef();

    const [columnDefs] = useState([
        { field: 'invoiceNumber', headerName: 'Invoice #', flex: 1, maxWidth: 120 },
        { field: 'accountName', headerName: 'Account', flex: 2 },
        { field: 'orderNumber', headerName: 'Order #', flex: 1, maxWidth: 120 },
        {
            field: 'invoiceDate',
            headerName: 'Invoice Date',
            flex: 1,
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            flex: 1,
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            field: 'daysPastDue',
            headerName: 'Days Past Due',
            flex: 1,
            maxWidth: 120
        },
        {
            field: 'invoiceTotal',
            headerName: 'Invoice Total',
            flex: 1,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'balanceDue',
            headerName: 'Balance Due',
            flex: 1,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
    ]);

    const gridOptions = {
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
    };

    const fetchInvoices = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await get(`/invoice/GetDueInvoiceReport?days=${days}`);
            setInvoices(response);
        } catch (err) {
            console.error('Error fetching aging invoices:', err);
            setError('Error fetching aging invoices');
        } finally {
            setLoading(false);
        }
    }, [days]);

    const handleRunReport = () => {
        fetchInvoices();
    };

    const onQuickFilterChanged = useCallback((event) => {
        gridRef.current.api.setQuickFilter(event.target.value);
        setQuickFilterText(event.target.value);
    }, []);

    useEffect(() => {
        SetPageTitle("Aging Invoice Report");
        fetchInvoices(); // Fetch invoices on initial load
    }, [fetchInvoices]);

    return (
        <div className="page-wrapper">
            <div className="mb-4 flex items-center space-x-4">
                <div>
                    <label htmlFor="days" className="mr-2">Past Due Days:</label>
                    <input
                        type="number"
                        id="days"
                        value={days}
                        onChange={(e) => setDays(Number(e.target.value))}
                        className="border rounded px-2 py-1 w-20"
                    />
                </div>
                <button onClick={handleRunReport} className="btn btn-sm btn-primary">
                    Run Report
                </button>
                <div>
                    <input
                        type="text"
                        value={quickFilterText}
                        onChange={onQuickFilterChanged}
                        placeholder="Search..."
                        className="border rounded px-2 py-1"
                    />
                </div>
            </div>

            {loading && <ShowHideLoading show={loading} />}
            {error && <ShowHideError msg={error} />}

            {!loading && !error && (
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={invoices}
                        columnDefs={columnDefs}
                        gridOptions={gridOptions}
                    />
                </div>
            )}
        </div>
    );
};