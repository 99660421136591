import React, { Component } from 'react';
import { CheckPayments } from './CheckPayments';

export class AchPendingPayments extends Component {
    static displayName = AchPendingPayments.name;

    //constructor(props) {
    //    super(props);
    //}

    render() {
        return (
            <CheckPayments loadAchVendors={true}></CheckPayments>
        );
    }
}