import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-daisyui'

import {
    IsSuccessStandardResponseAndDataPOST, StandardDataAndResponsePOST,
    StandardIdAndResponseGET
} from '../../js/dataHelpers';
import {
    Exists, handleFieldChange, IdExists, IsFalse, IsTrue, NotEmpty, NotExists,
    NotStringEmpty,
    ParseIntSafe, PreventDefault, SetFocusById, StringEmpty, Timeout
} from '../../js/formHelpers';

//import { createAccountModel } from './_accountHelpers.js';
//import {
//    handleFieldChange, isSelectedDropdown, isValidDate, GetStoreSelectList
//} from '../../js/formHelpers.js';


import { ListData } from '../_reactHelpers/ListData.js';
import { FileUploader } from '../_reactHelpers/FileUploader.js';

export const AccountCreditNotes = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [accountId, setAccountId] = useState(getAccountId());
    const [creditNotesList, setCreditNotesList] = useState([]);
    const [attList, setAttList] = useState([]);
    
    const [newNoteData, setNewNoteData] = useState(newNoteModel());
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [newAttData, setNewAttData] = useState(newAttModel());    
    const [isAttModalOpen, setIsAttModalOpen] = useState(false);    

    const [redirectSubmit, setRedirectSubmit] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const updateMsg = " updated successfully.";
    const updateErrMsg = "There was an issue updating the account. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    function getAccountId() {
        var accountId = 0;
        try {
            accountId = location.state.accountId;
            if (!accountId) {
                accountId = 0;
            }
        } catch (error) {
            accountId = 0;
        }
        //console.log("getAccountId: ", accountId);
        return accountId;
    }


    function newNoteModel() {
        var model = {
            accountId: accountId,
            note: "",
            pinned: false,
            important: false
        };
        return model;
    }

    function newAttModel() {
        var model = {
            accountId: accountId,
            accountAttachmentId: 0,
            description: "",
            name: ""
        };
        return model;
    }

    function backToClick(accountStatus, accountMessage) {
        navigate('/Accounts', {
            replace: true,
            state: { useSavedFiltersAccount: true, accountStatus, accountMessage }
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (NotExists(msg)) {
            msg = "";
        }
        if (NotExists(cssClass)) {
            cssClass = "";
        }

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }


    const handleNewNoteChange = (e) => {
        updateMessage();

        var data = handleFieldChange(e);
        if (data) {

            setNewNoteData((newNoteData) => ({
                ...newNoteData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleNewNoteChange data");
        }
    };

    //const handleNewAttChange = (e) => {
    //    updateMessage();

    //    var data = handleFieldChange(e);
    //    if (data) {

    //        setNewAttData((newAttData) => ({
    //            ...newAttData,
    //            [data.name]: data.value
    //        }));
    //    }
    //    else {
    //        updateMessage(defaultErrMsg, "text-red", "null handleNewAttChange data");
    //    }
    //};

    //----- Load Account Credit Notes Data -------

    const handleCreditNotesLoad = async () => {

        // CreditNotes tab should only load for existing Account
        if (IdExists(accountId)) {
            var data = await StandardIdAndResponseGET(accountId, "accounts/GetAccountCreditNotes/", "handleCreditNotesLoad()");
            if (Exists(data)) {
                setCreditNotesList(data);
            }
            else {
                updateMessage(defaultErrMsg, "text-red");
            }
        }
        else {
            updateMessage(defaultErrMsg, "text-red");
        }
    };

    //----- POST -------

    //function btnSubmitClick(e, redirectSubmit) {
    //    setRedirectSubmit(redirectSubmit);
    //}

    async function SaveAccountCreditNotePost() {
        var response = null;

        if (Exists(newNoteData)) {

            if (NotStringEmpty(newNoteData.note)) {
                var postModel = JSON.stringify(newNoteData);
                var endpoint = "accounts/SaveAccountCreditNotes";
                var typeStr = "SaveAccountCreditNotesPost";
                response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
            }
            else {
                updateMessage("Note is required", "text-red");
            }
        }
        else {
            response = "No POST data for SaveAccountCreditNotesPost().";
        }
        return response;
    }

    async function DeleteCreditAccountNotePOST(noteId) {
        var response = null;

        if (IdExists(noteId)) {
            var model = newNoteModel();
            model.accountNoteId = noteId;

            var postModel = JSON.stringify(model);
            var endpoint = "accounts/DeleteAccountNote";
            var typeStr = "DeleteCreditAccountNotePOST";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        }
        else {
            response = "No POST data for DeleteCreditAccountNotePOST()";
        }
        return response;
    }

    const handleOpenModal = () => setIsModalOpen(true);

    function openModal() {
        Timeout(200, SetFocusById("creditNotesTextArea"));
        handleOpenModal();        
    }

    async function handleCloseModal(saveNote) {
        var resp = null;

        if (IsTrue(saveNote)) {
            resp = await SaveAccountCreditNotePost();
            //var success = IsSuccessStandardResponseAndDataPOST(resp);
            var id = ParseIntSafe(resp);

            if (IdExists(id)) {
                setNewNoteData(newNoteModel());
                setIsModalOpen(false);
                handleCreditNotesLoad();
            }
        }
        else {
            setNewNoteData(newNoteModel());
            setIsModalOpen(false);
        }
    }

    async function deleteNote(e, noteId) {
        PreventDefault(e);

        if (window.confirm("Are you sure you want to delete this Note?")) {
            var resp = await DeleteCreditAccountNotePOST(noteId);
            //var success = IsSuccessStandardResponseAndDataPOST(resp);
            handleCreditNotesLoad();
        }
    }

    //----- Load Account Attachments Data -------

    const handleAttachmentsLoad = async () => {

        // CreditNotes tab should only load for existing Account
        if (IdExists(accountId)) {
            var data = await StandardIdAndResponseGET(accountId, "accounts/GetAccountAttachments/", "handleCreditNotesLoad()");
            if (Exists(data)) {
                setAttList(data);
            }
            else {
                updateMessage(defaultErrMsg, "text-red");
            }
        }
        else {
            updateMessage(defaultErrMsg, "text-red");
        }
    };

    const handleOpenAttModal = () => setIsAttModalOpen(true);

    async function handleCloseAttModal() {
        handleAttachmentsLoad();
        setIsAttModalOpen(false);
    }

    async function deleteAttachment(e, attId) {
        PreventDefault(e);

        if (window.confirm("Are you sure you want to delete this attachment?")) {
            var resp = await DeleteAttachmentsPOST(attId);
            handleAttachmentsLoad();
        }
    }

    async function DeleteAttachmentsPOST(attId) {
        var response = null;

        if (IdExists(attId)) {
            var model = newAttModel();
            model.accountAttachmentId = attId;

            var postModel = JSON.stringify(model);
            var endpoint = "data/DeleteAccountAttachment";
            var typeStr = "DeleteAccountAttachmentPOST";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        }
        else {
            response = "No POST data for DeleteCreditAccountNotePOST()";
        }
        return response;
    }

    // ON LOAD
    useEffect(() => {        
        handleCreditNotesLoad();
        handleAttachmentsLoad();
    }, []);

    return (
        <div className="p-4 pt-0">
            {IdExists(accountId) &&
                <div>
                    <div className="act-form">
                        <div className="flex-wrap forms-page clear">
                            <div>
                                <div className="form-break !pb-0">
                                    <hr className="form-break-line" />
                                    <span className="form-break-title">
                                        Notes
                                    </span>
                                </div>

                                <div className="float-right">
                                    <button type="button" className="btn-search !pt-0 !pb-0"
                                        onClick={(e) => openModal()}>
                                        + Note
                                    </button>
                                </div>
                            </div>

                            <div className="clear pt-2">
                                <div className="min-h-[50px] rounded border-gray-200 border w-full h-[60vh] overflow-x-hidden overflow-y-auto">
                                    {creditNotesList &&                                    
                                        <ListData dataLines={creditNotesList} type="account-notes" deleteFunc={deleteNote}></ListData>
                                    }
                                </div>
                            </div>

                            <div className="py-2">
                                <div className="float-right">
                                    <button type="button" className="btn-search !pt-0 !pb-0"
                                        onClick={(e) => handleOpenAttModal()}>
                                        + Attachment
                                    </button>
                                </div>
                            </div>                            
                            <div className="clear pt-2">
                                <div className="min-h-[50px] rounded border-gray-200 border w-full h-[14vh] overflow-x-hidden overflow-y-auto">
                                    {attList &&
                                        <ListData dataLines={attList} type="account-attachments" deleteFunc={deleteAttachment}></ListData>
                                    }
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div>
                <Modal open={isModalOpen} className="min-w-[800px] py-2">                    
                    <Modal.Header
                        className="font-bold mb-0 text-center pb-2 !text-secondary !text-underline">
                        + Note
                    </Modal.Header>
                    <Modal.Body>
                        <div className="min-h-[200px]">
                            <div className="w-full mb-4">
                                <div className="border-textarea">
                                    <textarea className="inp-textarea acct-notes-textarea !overflow-clip !px-2 !py-1"
                                        id="creditNotesTextArea" name="note" rows="6"
                                        value={newNoteData.note}
                                        onChange={handleNewNoteChange}
                                        autoFocus>
                                    </textarea>
                                </div>
                            </div>

                            <div className="details-form-field">
                                <label for="pinned" className="lbl-cb pr-4">
                                    Pin Note to Top
                                    <input id="pinned" name="pinned" type="checkbox"
                                        className="inp-cb"
                                        checked={newNoteData.pinned}
                                        onChange={handleNewNoteChange}
                                    />
                                </label>
                            </div>

                            <div className="details-form-field">
                                <label for="important" className="lbl-cb pr-4">
                                    Mark As Important
                                    <input id="important" name="important" type="checkbox"
                                        className="inp-cb"
                                        checked={newNoteData.important}
                                        onChange={handleNewNoteChange}
                                    />
                                </label>
                            </div>

                            <div className={"h-[30px] text-center " + messageClass}>
                                {message}
                            </div>
                            <div className="text-right pt-2 pb-2">
                                <button type="button" className="btn-cancel" onClick={(e) => handleCloseModal(false)}>
                                    Cancel
                                </button>
                                <button type="button" id="btnSubmit" className="btn-submit" onClick={(e) => handleCloseModal(true)}>
                                    Save & Close
                                </button>                                
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            <div>
                <Modal open={isAttModalOpen} className="min-w-[500px] py-2 px-4">
                    <Modal.Header
                        className="font-bold mb-0 text-center pb-2 !text-secondary !underline">
                        + Attachment
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <FileUploader accountId={accountId} handleClose={handleCloseAttModal}></FileUploader>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

