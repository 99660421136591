import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-daisyui'

import {
    IsSuccessStandardResponseAndDataPOST, StandardDataAndResponsePOST,
    StandardIdAndResponseGET
} from '../../js/dataHelpers';
import {
    Exists, handleFieldChange, IdExists, IsFalse, IsTrue, NotEmpty, NotExists,
    NotStringEmpty,
    ParseIntSafe, PreventDefault, SetFocusById, Timeout
} from '../../js/formHelpers';

//import { createAccountModel } from './_accountHelpers.js';
//import {
//    handleFieldChange, isSelectedDropdown, isValidDate, GetStoreSelectList
//} from '../../js/formHelpers.js';

import { ListData } from '../_reactHelpers/ListData.js';

export const AccountSalesNotes = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [accountId, setAccountId] = useState(getAccountId());
    const [salesNotesList, setSalesNotesList] = useState([]);
    const [salesNotesData, setSalesNotesData] = useState(newSalesNotesModel());

    const [newNoteData, setNewNoteData] = useState(newNoteModel());

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [redirectSubmit, setRedirectSubmit] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const updateMsg = " updated successfully.";
    const updateErrMsg = "There was an issue updating the account. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    function getAccountId() {
        var accountId = 0;
        try {
            accountId = location.state.accountId;
            if (!accountId) {
                accountId = 0;
            }
        } catch (error) {
            accountId = 0;
        }
        //console.log("getAccountId: ", accountId);
        return accountId;
    }

    function newSalesNotesModel() {
        var model = {
            accountId: 0,
            name: "",
            defaultPriceLevelId: 0,
            salesNotes: ""
        };
        return model;
    }

    function newNoteModel() {
        var model = {
            accountId: accountId,
            accountNoteId: 0,
            note: "",
            pinned: false,
            important: false
        };
        return model;
    }

    function backToClick(accountStatus, accountMessage) {
        navigate('/Accounts', {
            replace: true,
            state: { useSavedFiltersAccount: true, accountStatus, accountMessage }
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (NotExists(msg)) {
            msg = "";
        }
        if (NotExists(cssClass)) {
            cssClass = "";
        }

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    const handleChange = (e) => {
        updateMessage();

        var data = handleFieldChange(e);
        if (data) {

            setSalesNotesData((salesNotesData) => ({
                ...salesNotesData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };


    const handleNewNoteChange = (e) => {
        updateMessage();

        var data = handleFieldChange(e);
        if (data) {

            setNewNoteData((newNoteData) => ({
                ...newNoteData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleNewNoteChange data");
        }
    };

    //----- Load Account Sales Notes Data -------

    //function setSalesNotesModel(accountData) {
    //    var model = newSalesNotesModel();
    //    model.accountId = accountData.accountId;
    //    model.name = accountData.name;
    //    model.defaultPriceLevelId = accountData.defaultPriceLevelId;
    //    model.salesNotes = accountData.salesNotes;
    //    return model;
    //}

    const handleAccountSalesNotesLoad = async () => {

        // SalesNotes tab should only load for existing Account
        if (IdExists(accountId)) {
            var data = await StandardIdAndResponseGET(accountId, "accounts/GetAccount/", "handleAccountSalesNotesLoad()");
            if (Exists(data)) {                                
                setSalesNotesData(data);
            }
            else {
                updateMessage(defaultErrMsg, "text-red");
            }
        }
        else {
            updateMessage(defaultErrMsg, "text-red");
        }
    };

    const handleSalesNotesLoad = async () => {

        // SalesNotes tab should only load for existing Account
        if (IdExists(accountId)) {
            var data = await StandardIdAndResponseGET(accountId, "accounts/GetAccountSalesNotes/", "handleSalesNotesLoad()");
            if (Exists(data)) {
                setSalesNotesList(data)
            }
            else {
                updateMessage(defaultErrMsg, "text-red");
            }
        }
        else {
            updateMessage(defaultErrMsg, "text-red");
        }
    };

    //----- POST -------

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    }

    //function validateData() {
    //    var valid = true;
    //    var message = "";

    //    if (NotExists(salesNotesData.defaultPriceLevelId)) {
    //        valid = false;
    //        message = "Standard Account Pricing is required";
    //    }

    //    if (IsFalse(valid)) {
    //        updateMessage(message, "text-red");
    //    }
    //    return valid;
    //}

    //const handleSubmit = async (e) => {
    //    PreventDefault(e);
    //    updateMessage();

    //    var valid = validateData();
    //    if (IsTrue(valid)) {
    //        //POST data
    //        var response = await SaveAccountSalesNotesPost(salesNotesData);

    //        //POST response
    //        var success = false;
    //        var message = "";
    //        //if (IsSuccessStandardResponseAndDataPOST(response)) {
    //        if (Exists(response)) {
    //            //response returns accountId if success
    //            var pAccountId = ParseIntSafe(response);
    //            if (IdExists(pAccountId)) {
    //                success = true;
    //                message = salesNotesData.name + updateMsg;

    //                if (redirectSubmit) {
    //                    backToClick("update", message);
    //                }
    //            }
    //        }

    //        var msgClass = IsTrue(success) ? "text-green" : "text-red";
    //        updateMessage(message, msgClass);
    //    }
    //}

    async function SaveAccountSalesNotePost() {
        var response = null;

        if (Exists(newNoteData)) {

            if (NotStringEmpty(newNoteData.note)) {
                var postModel = JSON.stringify(newNoteData);
                var endpoint = "accounts/SaveAccountSalesNotes";
                var typeStr = "SaveAccountSalesNotesPost";
                response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
            }
            else {
                updateMessage("Note is required", "text-red");
            }
        }
        else {
            response = "No POST data for SaveAccountSalesNotesPost().";
        }
        return response;
    }

    async function DeleteSalesAccountNotePOST(noteId) {
        var response = null;

        if (IdExists(noteId)) {
            var model = newNoteModel();
            model.accountNoteId = noteId;

            var postModel = JSON.stringify(model);
            var endpoint = "accounts/DeleteAccountNote";
            var typeStr = "DeleteSalesAccountNotePOST";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);            
        }
        else {
            response = "No POST data for DeleteSalesAccountNotePOST().";
        }
        return response;
    }

    const handleOpenModal = () => setIsModalOpen(true);
        
    function openModal() {
        Timeout(200, SetFocusById("salesNotesTextArea"));
        handleOpenModal();
    }

    async function handleCloseModal(saveNote) {
        var resp = null;

        if (IsTrue(saveNote)) {
            resp = await SaveAccountSalesNotePost();
            //var success = IsSuccessStandardResponseAndDataPOST(resp);
            var id = ParseIntSafe(resp);

            //if (IsTrue(success)) {
            if (IdExists(id)) {            
                setNewNoteData(newNoteModel());
                setIsModalOpen(false);
                handleSalesNotesLoad();
            }
        }
        else {
            setNewNoteData(newNoteModel());
            setIsModalOpen(false);
        }
    }

    async function deleteNote(e, noteId) {
        PreventDefault(e);

        if (window.confirm("Are you sure you want to delete this Note?")) {
            var resp = await DeleteSalesAccountNotePOST(noteId);
            //var success = IsSuccessStandardResponseAndDataPOST(resp);
            handleSalesNotesLoad();
        }
    }

    // ON LOAD
    useEffect(() => {
        handleAccountSalesNotesLoad();
        handleSalesNotesLoad();
    }, []);

    return (
        <div className="p-4 pt-0">
            {IdExists(accountId) &&
                <div>
                    <div className="act-form">
                        <div className="flex-wrap forms-page clear">
                            <div>
                                <div className="form-break !pb-2">
                                    <hr className="form-break-line" />
                                    <span className="form-break-title">
                                        Notes
                                    </span>
                                </div>

                                <div className="float-left">
                                    <div className="details-form-field-sel1">
                                        <label for="defaultPriceLevelId" className="lbl-text2 !w-[200px]">
                                            Standard Account Pricing:
                                        </label>
                                        <select className="sel-dd mark !w-[80px]"
                                            id="defaultPriceLevelId" name="defaultPriceLevelId"
                                            value={salesNotesData.defaultPriceLevelId}
                                            onChange={handleChange}
                                        >
                                            <option value="0">BC</option>
                                            <option value="1">BC1</option>
                                            <option value="2">BC2</option>
                                            <option value="3">BC3</option>
                                            <option value="5">BC5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="float-right">
                                    <button type="button" className="btn-search !pt-0 !pb-0"
                                        onClick={(e) => openModal()}>
                                        + Note
                                    </button>
                                </div>
                            </div>

                            <div className="clear pt-2">
                                <div className="rounded border-gray-200 border w-full h-[78vh] overflow-x-hidden overflow-y-auto">
                                {salesNotesList &&                                    
                                    <ListData dataLines={salesNotesList} type="account-notes" deleteFunc={deleteNote}></ListData>                                    
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div>
                <Modal open={isModalOpen} className="min-w-[800px] py-2">                    
                    <Modal.Header className="font-bold mb-0 text-center pb-2 !text-secondary !text-underline">
                        + Note
                    </Modal.Header>
                    <Modal.Body>
                        <div className="formVendorProductRate min-h-[200px]">

                            <div className="w-full mb-4">
                                <div className="border-textarea">
                                    <textarea className="inp-textarea acct-notes-textarea !overflow-clip !px-2 !py-1"
                                        id="salesNotesTextArea" name="note" rows="6"
                                        value={newNoteData.note}
                                        onChange={handleNewNoteChange}
                                        autoFocus >
                                    </textarea>
                                </div>
                            </div>

                            <div className="details-form-field">
                                <label for="pinned" className="lbl-cb pr-4">
                                    Pin Note to Top
                                    <input id="pinned" name="pinned" type="checkbox"
                                        className="inp-cb"
                                        checked={newNoteData.pinned}
                                        onChange={handleNewNoteChange}
                                    />
                                </label>
                            </div>

                            <div className={"h-[30px] text-center " + messageClass}>
                                {message}
                            </div>
                            <div className="text-right pt-2 pb-2">
                                <button type="button" className="btn-cancel" onClick={(e) => handleCloseModal(false)}>
                                    Cancel
                                </button>
                                <button type="button" className="btn-submit" onClick={(e) => handleCloseModal(true)}>
                                    Save & Close
                                </button>                                
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

