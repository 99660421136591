import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { EditDepositModal } from './EditDepositModal';

export function DepositTable({ deposits, fetchDeposits, openAddModal }) {
    //updateDeposits, updateFilteredDeposits, 

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDeposit, setSelectedDeposit] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    //const updateDepositsHandler = useCallback((updatedDeposit) => {
    //    //console.log('Updated deposit:', updatedDeposit);

    //    const updatedData = deposits.map(deposit =>
    //        deposit.depositRegisterId === updatedDeposit.depositRegisterId ? updatedDeposit : deposit
    //    );
    //    updateDeposits(updatedData);
    //    updateFilteredDeposits(updatedData);

    //    fetchDeposits();
    //}, [deposits, updateDeposits, updateFilteredDeposits, fetchDeposits]);

    const columnDefs = useMemo(() => [
        { field: 'depositRegisterId', headerName: 'ID', hide: true },
        { field: 'storeName', headerName: 'Store', },
        { field: 'registerName', headerName: 'Register Name', },
        { field: 'registerType', headerName: 'Register Type', },
        { field: 'glAccountCode', headerName: 'GL Account Code', },
        {
            headerName: '', sortable: false, filter: false, maxWidth: 80,
            cellRenderer: params => (
                <button className="btn-grid"
                    onClick={() => {
                        setSelectedDeposit(params.data);
                        setModalOpen(true);
                    }}>Edit</button>
            ),
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const onFilterTextBoxChanged = useCallback((event) => {
        setQuickFilterText(event.target.value);
    }, []);

    return (
        <div>
            <div className="flex-wrapper mb-2">
                <div className="flex-grow">
                    <input type="text" id="filter-text-box" placeholder="Filter..." className="grid-filter"
                        onChange={onFilterTextBoxChanged}
                    />
                </div>
                <button onClick={openAddModal} className="btn-small">
                    Add Deposit Register
                </button>
            </div>

            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={deposits}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={20}
                    rowSelection="single"
                    quickFilterText={quickFilterText}
                />
            </div>
            
            {modalOpen && selectedDeposit &&
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <EditDepositModal
                            depositData={selectedDeposit}
                            onClose={() => {
                                setModalOpen(false);
                                setSelectedDeposit(null);
                            }}
                            onUpdateDeposit={fetchDeposits}
                        />
                    </div>
                </div>
            }
        </div>
    );
}