import React, { useState, useEffect } from 'react';

import { DepositTable } from './DepositTable';
import { DepositForm } from './DepositForm';

import { get } from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';

export function Deposit() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Deposit Registers");

    const [deposits, setDeposits] = useState([]);
    //const [filteredDeposits, setFilteredDeposits] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //const [search, setSearch] = useState('');
    const [showFormModal, setShowFormModal] = useState(false);

    const handleAddDeposit = (newDeposit) => {
        setDeposits([...deposits, newDeposit]);
        //setFilteredDeposits([...deposits, newDeposit]);
        setShowFormModal(false);
    };

    //const handleUpdateDeposits = (updatedDeposits) => {
    //    setDeposits(updatedDeposits);
    //    setFilteredDeposits(updatedDeposits);
    //};

    //const handleUpdateFilteredDeposits = (updatedDeposits) => {
    //    setFilteredDeposits(updatedDeposits);
    //};

    const fetchDeposits = async () => {
        try {
            const response = await get('/depositregisters/GetDepositRegisters');
            setDeposits(response);
            //setFilteredDeposits(response);

            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        SetPageTitle("Deposit Registers");
        fetchDeposits();
    }, []);

    if (loading) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error) {
        return <div className="text-center text-xl mt-[10%] text-red">{error}</div>;
    }

    return (
        <div className="mx-auto py-2 px-6">
            <DepositTable
                deposits={deposits} //filteredDeposits
                //updateDeposits={handleUpdateDeposits}
                //updateFilteredDeposits={handleUpdateFilteredDeposits}
                fetchDeposits={() => fetchDeposits()}
                openAddModal={() => setShowFormModal(true)}
            />

            {showFormModal &&
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <DepositForm onAddDeposit={handleAddDeposit} onClose={() => setShowFormModal(false)} />
                    </div>
                </div>
            }
        </div>
    );
}