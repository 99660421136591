import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { AddEditInvoiceModal } from './AddEditInvoiceModal'; 
import { AgingReportModal } from './AgingReportModal';
import { PaymentModalContent } from '../Accounting/PaymentModal';
import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';
import { DateFilter } from '../_reactHelpers/DateFilter';
import RadioButtonList from '../_reactHelpers/RadioButtonList';

import { get, post } from '../../services/apiService';
import {
    AgCurrencyMxWd,
    AgDateMxWd,
    defaultErrMsg, Exists, GetDateNow, GetDisplayCurrencyStr,
    GetDisplayDateStr, handleFieldChange, IdExists, IsFalse, IsTrue, NotExists, NotStringEmpty, StringEmpty
} from '../../js/formHelpers';
import { GetRblValueCheckedByClass, SetPageTitle, StringContains } from '../../js/helpers';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';

export const SearchReceivables = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - ");

    const [isPostPayments, setIsPostPayments] = useState(props.isPostPayments);
    const location = useLocation();

    const [invoices, setInvoices] = useState([]);
    const [stores, setStores] = useState([]);

    const dateTypes = ["Invoice Date", "Due Date", "Paid Date"];
    const rblClassName = "rblDateType";
    const [dateType, setDateType] = useState("InvoiceDate");

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [accountId, setAccountId] = useState(null);

    const [invoicesForPayment, setInvoicesForPayment] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);

    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const [quickFilterText, setQuickFilterText] = useState('');

    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const gridRef = useRef(null);
    const [gridApi, setGridApi] = useState(null);

    const columnDefs = [
        {
            headerName: '', field: 'selected',
            checkboxSelection: params => !params.data.isGroup,
            headerCheckboxSelection: true,
            maxWidth: 50,
            //pinned: 'left',
        },
        {
            field: 'accountName', headerName: 'Account',
            rowGroup: true,
            cellRenderer: params => {
                if (params.data.isGroup) {
                    return `${params.value} (${params.data.children.length})`;
                }
                return params.value;
            }
        },
        { field: 'invoiceNumber', headerName: 'Invoice #', },
        { field: 'orderNumber', headerName: 'Order #', },
        { field: 'storeName', headerName: 'Store', maxWidth: 120 },
        //{ field: 'accountName', headerName: 'Account', minWidth:200, rowGroup: true },
        {
            field: 'invoiceDate', headerName: 'Invoice Date', maxWidth: AgDateMxWd(),
            valueFormatter: (params) => GetDisplayDateStr(params.value)
        },
        {
            field: 'dueDate', headerName: 'Due Date', maxWidth: AgDateMxWd(),
            valueFormatter: (params) => GetDisplayDateStr(params.value)
        },
        {
            field: 'invoiceTotal', headerName: 'Total', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'status', headerName: 'Status', maxWidth: 100,
            valueGetter: params => getInvoiceStatus(params.data.status)
        },
        //{
        //    headerName: '', maxWidth: 160,
        //    cellRenderer: params => (
        //        <button
        //            onClick={() => handleApplyPayment(params.data)}
        //            className="btn-grid !bg-green-500 !hover:bg-green-500">
        //            Apply Payment
        //        </button>
        //    ),
        //    rowGroup: false,
        //    hide: !isPostPayments
        //},
        {
            headerName: '', maxWidth: 80, filter: false, sortable: false,
            cellRenderer: params => (
                <button onClick={() => handleEditClick(params.data)} className="btn-grid">Edit</button>
            )
        }
    ];

    const autoGroupColumnDef = useMemo(() => ({
        headerName: "Account",
        minWidth: 200,
        field: 'accountName',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            checkbox: true,
            suppressCount: true,
            innerRenderer: params => {
                if (params.node.group) {
                    return `${params.value} (${params.node.allChildrenCount})`;
                }
                return params.value;
            }
        }
    }), []);

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            enableRangeSelection: true,
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.getColumnDef('accountName').rowGroup = true;
        params.api.refreshClientSideRowModel('group');
        params.api.sizeColumnsToFit();

        if (isPostPayments) {
            params.api.forEachNode(function (node) {
                if (node.group) {
                    node.setExpanded(false);
                }
            });
        }
    }, []);

    function getInvoiceStatus(status) {
        if (status === 1) {
            return "Unpaid";
        }
        else if (status === 2) {
            return "Paid";
        }
    }

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    async function getInvoices() {        
        setInvoices([]);
        setUnpaidInvoices([]);

        try {
            if (IsTrue(isPostPayments)) {
                await fetchUnpaidInvoices();
            }
            else {
                await fetchPaidInvoices();
            }

        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }
    }

    async function fetchPaidInvoices() {
        try {
            if (StringEmpty(dateType) || StringEmpty(startDate) || StringEmpty(endDate)) {
                updateMessage('Dates and Date Type are required.', 'text-red');
                return;
            }

            const response = await get('/invoice/GetInvoices', { params: { startDate, endDate, dateType } });
            const transformedData = response.map(invoice => ({
                ...invoice,
                invoiceLineItems: invoice.invoiceLineItems
            }));
            setInvoices(transformedData);

        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }
    }

    async function fetchUnpaidInvoices() {
        try {
            if (StringEmpty(endDate)) {
                updateMessage('Due Date is required.', 'text-red');
                return;
            }

            const response = await get('/invoice/GetUnpaidInvoices', {
                params: { dueDate: endDate, accountId: accountId }
            });
            const transformedData = response.map(invoice => ({
                ...invoice,
            }));

            setUnpaidInvoices(transformedData);

            if (gridApi) {
                gridApi.setRowData(transformedData);
                gridApi.forEachNode(function (node) {
                    if (node.group) {
                        node.setExpanded(false);
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching unpaid invoices:', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchStores = async () => {
        setLoading(true);

        try {
            const response = await get("/store/GetAllStores");
            const storeData = response || [];

            setStores(storeData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching stores:', error);
            setLoading(false);
        }
    };

    const handleInvoiceSubmit = useCallback(async (formData) => {
        try {

            const response = await post(`/invoice/CreateUpdateInvoice`, formData);
            if (IdExists(response)) {
                setModalOpen(false);
                setIsEditModalOpen(false);

                setMessage("Invoice saved successfully.");
                setMessageClass(" text-green ");

                getInvoices();               
            }
            else {
                alert(defaultErrMsg());
                //throw defaultErrMsg();
            }

        } catch (error) {
            console.error('Error saving the invoice:', error);
            throw error;
        }
    }, []);

    //const handleApplyPayment = (invoice) => {
    //    console.log('Added invoice to payment:', invoice);
    //    setInvoicesForPayment([invoice]);
    //    setIsPaymentModalOpen(true);
    //};

    const handleEditClick = useCallback((invoice) => {
        setSelectedInvoice({
            ...invoice,
            invoiceLineItems: invoice.invoiceLineItems || []
        });
        setIsEditModalOpen(true);
    }, []);

    const handleAddClick = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleQuickFilterChange = useCallback((event) => {
        setQuickFilterText(event.target.value);
    }, []);

    const onSelectionChanged = useCallback(() => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.flatMap(node =>
            node.data.isGroup ? node.data.children : node.data
        );
        setSelectedInvoices(selectedData);
    }, []);

    const applyPayment = async (payments) => {
        //setLoading(true);
        //console.log('Applying payments:', payments);

        try {
            const response = await post('/invoice/ApplyPayment', payments);
            //console.log('Payment response:', response);

            await fetchUnpaidInvoices();
        }
        catch (error) {
            console.error('Error applying payments:', error);
        }
        finally {
            setLoading(false);
            setIsPaymentModalOpen(false);
        }
    };

    const applyPaymentToSelected = () => {
        if (selectedInvoices.length === 0) {
            alert('Please select at least one invoice to apply payment.');
            return;
        }

        const paymentsToApply = selectedInvoices.map(invoice => ({
            invoiceId: invoice.invoiceId,
            invoiceNumber: invoice.invoiceNumber,
            invoiceTotal: invoice.invoiceTotal,
            paymentAmount: invoice.invoiceTotal,
            accountName: invoice.accountName,
            depositRegisterId: null,
            paymentType: null,
            paymentTypeId: null,
            balanceDue: invoice.balanceDue,
            totalDue: invoice.balanceDue
        }));

        //console.log('Selected Invoices:', selectedInvoices);
        //console.log('Payments to apply:', paymentsToApply);

        setInvoicesForPayment(paymentsToApply);
        setIsPaymentModalOpen(true);
    };

    const onSubmitClosePayment = async (message) => {
        setIsPaymentModalOpen(false);

        try {
            setMessage(message);
            setMessageClass(" text-green ");
            if (StringContains(message, "error")) {
                setMessageClass(" text-red ");
            }

            await fetchUnpaidInvoices();
        }
        catch (error) {
            console.error('onSubmitClosePayment() error:', error);
        }
        finally {
        }
    };

    const handleSelectAccount = (selectedOption) => {
        setAccountId(selectedOption.accountId);
    };

    //const handleChange = (e) => {
    //    var data = handleFieldChange(e);        
    //    if (data) {
    //        if (data.name === "entityId") {
    //            setAccountId(data.value);
    //        }
    //    }
    //    else {
    //        alert(defaultErrMsg())
    //    }
    //};

    //const handleInvoiceOrdersClick = async (payments) => {
    //    setLoading(true);

    //    try {
    //        const response = await post('/invoice/InvoiceAllOpenDeliveredOrders');            
    //        await fetchUnpaidInvoices();
    //    } catch (error) {
    //        console.error('handleInvoiceClick() error:', error);
    //    } finally {
    //        setLoading(false);
    //    }
    //};


    useEffect(() => {
        if (isPostPayments) {
            setDocTitle("Lumina - Post Payments");
            SetPageTitle("Post Payments");
        }
        else {
            setDocTitle("Lumina - Search Receivables");
            SetPageTitle("Search Receivables");
        }
        fetchStores();
    }, []);

    if (loading) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div className="page-wrapper !pt-1">
            <div className="flex-wrapper">
                <div className="flex-1 mb-2">
                    <div className="">
                        {IsFalse(isPostPayments) &&
                            <div className="flex-wrapper pt-2">
                                <DateFilter fromDate={startDate} setFromDate={setStartDate} fromDateStr="Start Date:"
                                    toDate={endDate} setToDate={setEndDate} toDateStr="End Date:" enterFunction={getInvoices}
                                />
                                <div className="">
                                    <button onClick={e => getInvoices()} className="btn-submit">
                                        Search Invoices
                                    </button>
                                </div>
                                <div className="">
                                    <RadioButtonList rblClassName={rblClassName} valuesList={dateTypes} initialValue={dateType} setValue={setDateType} />
                                </div>
                            </div>
                        }

                        {IsTrue(isPostPayments) &&
                            <div className="flex-wrapper">
                                <div className="">
                                    <DateFilter toDate={endDate} setToDate={setEndDate} toDateStr="Due Date:" enterFunction={getInvoices} />
                                </div>
                                <div className="pl-2">
                                    <label className="label table-cell pl-4 pr-2">
                                        <span className="date-filter-label">Account:</span>
                                    </label>
                                    <div className="table-cell" >
                                        <EntityAutocomplete name="accountId" id="accountId"
                                            onSelect={handleSelectAccount}
                                            onChange={null}
                                            initialEntityId={accountId}
                                            entityType="Account"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="pl-4">
                                    <button onClick={e => getInvoices()} className="btn-small">Search Unpaid Invoices</button>
                                </div>
                                {/*<div className="pl-2">*/}
                                {/*    <button className="btn-small ml-2 !bg-green-500 !hover:bg-green-500"*/}
                                {/*        onClick={applyPaymentToSelected}*/}
                                {/*        disabled={!isPostPayments || selectedInvoices.length === 0}>*/}
                                {/*        Apply Payment to Selected ({selectedInvoices.length})*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        }
                    </div>
                </div>

                <div className="">
                    {IsTrue(isPostPayments) &&
                        <button className="btn-small mr-2 !bg-green-500 !hover:bg-green-500"
                            onClick={applyPaymentToSelected}
                            disabled={!isPostPayments || selectedInvoices.length === 0}>
                            Apply Payment to Selected ({selectedInvoices.length})
                        </button>
                    }
                    {IsFalse(isPostPayments) &&
                        <button onClick={handleAddClick} className="btn-small">
                            Add New Invoice
                        </button>
                    }
                </div>
            </div>

            <div>
                <div className="table-cell pr-2">
                    <input type="text" id="quickFilter" className="grid-filter !w-[300px]"
                        placeholder="Filter..."
                        value={quickFilterText}
                        onChange={handleQuickFilterChange}
                    />
                </div>
                <div className={"table-cell " + messageClass}>{message}</div>
            </div>

            <div className="ag-theme-alpine ag-grid-act pt-2" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={isPostPayments ? unpaidInvoices : invoices}
                    columnDefs={columnDefs}
                    gridOptions={gridOptions}
                    onGridReady={onGridReady}
                    quickFilterText={quickFilterText}
                    pagination={true}
                    paginationPageSize={20}
                    rowSelection="multiple"
                    //suppressRowClickSelection={true}
                    onSelectionChanged={onSelectionChanged}
                    groupRemoveSingleChildren={true}
                    groupSelectsChildren={true}
                    autoGroupColumnDef={autoGroupColumnDef}
                    groupDisplayType={'groupRows'}
                    getRowId={(params) => params.data.invoiceId}
                />
            </div>

            {/* ADD */}
            {isModalOpen && (
                <AddEditInvoiceModal
                    initialInvoiceData={null}
                    handleSubmit={handleInvoiceSubmit}
                    stores={stores}
                    onHide={() => setModalOpen(false)}
                />
            )}

            {/* EDIT */}
            {isEditModalOpen && (
                <AddEditInvoiceModal
                    initialInvoiceData={selectedInvoice || {}}
                    handleSubmit={handleInvoiceSubmit}
                    stores={stores}
                    onHide={() => setIsEditModalOpen(false)}
                />
            )}

            {/*{isAgingReportModalOpen && (*/}
            {/*    <AgingReportModal*/}
            {/*        show={isAgingReportModalOpen}*/}
            {/*        onHide={() => setIsAgingReportModalOpen(false)}*/}
            {/*        selectedDate={getYesterday()}*/}
            {/*    />*/}
            {/*)}*/}

            {isPaymentModalOpen && invoicesForPayment.length > 0 && (            
                <PaymentModalContent invoices={invoicesForPayment}
                    confirmPayment={applyPayment} close={onSubmitClosePayment} />            
            )}
        </div>
    );
}