import React, { useState, useCallback, useEffect } from 'react';
import { get } from '../../services/apiService';
import debounce from 'lodash/debounce';

export const VendorAutocomplete = ({ onSelect, initialVendorId = null, onChange }) => {
    const [inputValue, setInputValue] = useState('');

    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const fetchVendors = useCallback(
        debounce(async (query) => {
            if (query.length < 2) {
                setVendors([]);
                return;
            }
            setLoading(true);
            try {
                const response = await get(`vendors/search?query=${encodeURIComponent(query)}`);
                setVendors(response);
                setIsOpen(true);
            } catch (error) {
                console.error('Error fetching vendors:', error);
                setVendors([]);
            } finally {
                setLoading(false);
            }
        }, 300),
        []
    );

    const fetchVendorById = useCallback(async (id) => {
        try {
            const vendor = await get(`vendors/GetVendor/${id}`);

            setInputValue(vendor.name);
            onSelect(vendor);
            onChange({ target: { name: 'vendorId', value: vendor.id } });

            //console.log('Vendor by ID:', vendor);
        } catch (error) {
            console.error('Error fetching vendor by ID:', error);
        }
    }, [onSelect, onChange]);

    useEffect(() => {
        if (initialVendorId) {
            fetchVendorById(initialVendorId);
        }
    }, [initialVendorId, fetchVendorById]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        //console.log('newValue:', newValue);

        setInputValue(newValue);
        fetchVendors(newValue);
        onChange({ target: { name: 'vendorId', value: '' } });
    };

    const handleSelectVendor = (vendor) => {
        //console.log('New vendor selected:', vendor);

        setInputValue(vendor.name);
        setIsOpen(false);
        onSelect(vendor);
        onChange({ target: { name: 'vendorId', value: vendor.id } });
    };

    return (
        <div className="relative">
            <input
                type="text"
                className="select select-bordered focus:outline-none focus:border-blue-500 text-gray-700 w-full"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setIsOpen(true)}
                placeholder="Search vendor..."
            />
            {isOpen && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                    {loading && (
                        <li className="px-4 py-2 text-gray-500">Loading...</li>
                    )}
                    {!loading && vendors.length === 0 && (
                        <li className="px-4 py-2 text-gray-500">No vendors found</li>
                    )}
                    {vendors.map((vendor) => (
                        <li
                            key={vendor.id}
                            onClick={() => handleSelectVendor(vendor)}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                            {vendor.name}
                        </li>
                    ))}
                </ul>
            )}
            <input type="hidden" name="vendorId" value={0} />
        </div>
    );
}