import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { get } from '../../services/apiService';
import { Exists, IdExists } from '../../js/formHelpers';

export const EntityAutocomplete = ({ onSelect, initialEntityId = null, onChange, entityType }) => {

    const isVendorType = entityType === "Vendor";
    const isAccountType = entityType === "Account";
    const isOrder = entityType === "Order";

    const [inputValue, setInputValue] = useState('');
    const [entities, setEntities] = useState([]);
    
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchEntities = useCallback(
        debounce(async (query) => {

            if (query.length < 2) {
                setEntities([]);
                return;
            }

            setLoading(true);
            try {                
                let response = [];

                if (isVendorType) {
                    response = await get(`vendors/search?query=${encodeURIComponent(query)}`);
                }
                else if (isAccountType) {
                    response = await get(`accounts/search?query=${encodeURIComponent(query)}`);
                }
                else if (isOrder) {
                    response = await get(`invoice/searchOrdersReadyForInvoice?query=${encodeURIComponent(query)}`);
                }

                setEntities(response);
                setIsOpen(true);

            } catch (error) {
                console.error('Error fetching entties:', error);
                setEntities([]);
            } finally {
                setLoading(false);
            }
        }, 300),
        []
    );

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        console.log('newValue:', newValue);

        setInputValue(newValue);
        fetchEntities(newValue);
        if (Exists(onChange)) {
            onChange({ target: { name: 'entityId', value: '' } });
        }
    };

    const handleSelectEntity = (entity) => {
        //console.log('New entity selected:', entity);
        let entityName = "";
        let entityId = 0;     

        if (isVendorType) {
            entityName = entity.name;
            entityId = entity.vendorId;
        }
        else if (isAccountType) {
            entityName = entity.name;
            entityId = entity.accountId;            
        }
        else if (isOrder) {
            entityName = entity.orderNumber;
            entityId = entity.orderId;
        }

        setInputValue(entityName);
        setIsOpen(false);
        onSelect(entity);
        if (Exists(onChange)) {
            onChange({ target: { name: 'entityId', value: entityId } });
        }
    };

    const fetchEntityById = useCallback(async (id) => {
        try {
            if (IdExists(id)) {
                let entity = null;
                let entityName = "";
                let entityId = 0;

                if (isVendorType) {
                    let resp = await get(`accounts/GetVendor/${id}`);
                    entity = resp.value.data;
                    entityName = entity.name;
                    entityId = entity.vendorId;

                }
                else if (isAccountType) {
                    let resp = await get(`accounts/GetAccount/${id}`);
                    entity = resp.value.data;
                    entityName = entity.name;
                    entityId = entity.accountId;
                }
                else if (isOrder) {
                    let resp = await get(`orders/GetOrder/${id}/0/0`);
                    entity = resp.value.data;
                    entityName = entity.orderNumber;
                    entityId = entity.orderId;
                }

                //console.log('entity by ID:', entity);
                setInputValue(entityName);
                onSelect(entity.value.data);
                if (Exists(onChange)) {
                    onChange({ target: { name: 'entityId', value: entityId } });
                }
            }            
        } catch (error) {
            console.error('Error fetching entity by id:', error);
        }
    }, []);

    function getListItem(entity) {

        if (isOrder) {
            return <li className="px-4 py-1 hover:bg-gray-100 cursor-pointer text-sm"
                key={entity.id}
                onClick={() => handleSelectEntity(entity)}>{entity.orderNumber}</li>;
        }
        else {
            return <li className="px-4 py-1 hover:bg-gray-100 cursor-pointer text-sm"
                key={entity.id}
                onClick={() => handleSelectEntity(entity)}>{entity.name}</li>;
        }
    }

    useEffect(() => {
        if (IdExists(initialEntityId)) {
            fetchEntityById(initialEntityId);
        }
    }, []);

    return (
        <div className="relative">
            <input id={"inputEntityAutoComplete-" + entityType} className="select select-bordered focus:outline-none focus:border-blue-500 text-gray-700 w-full"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setIsOpen(true)}
                placeholder={"Search " + entityType + "s..."}
            />

            {isOpen && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                    {loading &&
                        (
                        <li className="px-4 py-2 text-gray-500 text-sm">Loading...</li>
                        )}
                    {!loading && entities.length === 0 &&
                        (
                        <li className="px-4 py-2 text-gray-500 text-sm">No {entityType} found</li>
                        )}
                    {entities.map((entity) =>
                    (
                        getListItem(entity)
                    ))}
                </ul>
            )}            
            <input id="hfEntityAcId" type="hidden" name="entityId" value={0} />
        </div>
    );
}