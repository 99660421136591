import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css
import '../../css/ag-orders.css'; // custom orders grid css

import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import { SetPageTitle } from '../../js/helpers.js';
import { defaultErrMsg, NotEmpty } from '../../js/formHelpers.js';

import {
    makePayment, postPaymentToDatabase, postTransactionToDatabase, makeAchPayment,
    getCardToken, getPaymentsByLastFour, makePaymentByToken, getAchToken,    
} from '../../services/paymentService.js';

import authService from '../../services/authService.js';
import { get, post } from '../../services/apiService.js'
import { formatCurrencyDecimal } from '../../js/formHelpers.js';

import '../../css/custom.css';

export const PaymentForm = ({ close, invoices, confirmPayment, submitClose }) => {

    const [activeTab, setActiveTab] = useState('newCard');
    const [order, setOrder] = useState('');
    const [orderInfo, setOrderInfo] = useState('');
    const [storeInfo, setStoreInfo] = useState('');
    const [amount, setAmount] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [fullName, setFullName] = useState('');
    const [description, setDescription] = useState('');
    const [cardNumber, setCardNumber] = useState('4111111111111111');       // TODO: remove - temp for testing  // useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCvv, setCardCvv] = useState('');
    const [saveCard, setSaveCard] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountNumberConfirm, setAccountNumberConfirm] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankAddress, setBankAddress] = useState('');
    const [bankCity, setBankCity] = useState('');
    const [bankState, setBankState] = useState('');
    const [bankZip, setBankZip] = useState('');
    const [bankPhone, setBankPhone] = useState('');
    const [totalDue, setTotalDue] = useState('');
    const [cash, setCash] = useState('');
    const [checkAmount, setCheckAmount] = useState('');
    const [checkNumber, setCheckNumber] = useState('');
    const [balanceDue, setBalanceDue] = useState('');
    const [changeDue, setChangeDue] = useState('');
    const [error, setError] = useState('');
    const [userId, setUserId] = useState(''); 
    const [orderNumber, setOrderNumber] = useState('');
    const [depositRegister, setDepositRegister] = useState('');
    const [depositRegisters, setDepositRegisters] = useState([]);
    const [today, setToday] = useState('');
    const [success, setSuccess] = useState('');
    const [billingGroupId, setBillingGroupIp] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [showGrid, setShowGrid] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [rowData, setRowData] = useState([]);
    const [formattedDate, setFormattedDate] = useState([]);
    const [accountType, setAccountType] = useState('');

    const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);

    // Passed from PaymentModal
    const [receivedData, setReceivedData] = useState(null);
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
    const [payments, setPayments] = useState([]);

    const gridRef = useRef();
    const inputRef = useRef();
    const location = useLocation();

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    // Data
    const { orderId } = useParams();
    const userData = authService.getCurrentUser();
    const navigate = useNavigate();

    // Response Codes
    const responseCodeMessages = {
        "00": "Transaction approved",
        "01": "Refer to card issuer",
        "02": "Refer to card issuer, special condition",
        "03": "Invalid merchant",
        "04": "Pick up card",
        "05": "Do not honor",
        "08": "Honor with ID",
        "10": "Partial Approval",
        "12": "Invalid Transaction",
        "13": "Invalid Amount",
        "14": "Invalid Card Number",
        "15": "Invalid Issuer",
        "30": "Format Error",
        "41": "Lost Card",
        "43": "Stolen Card",
        "51": "Insufficient funds/over credit limit",
        "54": "Card Expired",
        "55": "Invalid PIN",
        "57": "Transaction not permitted to issuer/cardholder",
        "58": "Transaction not permitted to acquirer/terminal",
        "61": "Exceeds withdrawal amount limit",
        "62": "Restricted card",
        "63": "Security Violation",
        "65": "Exceeds withdrawal count limit",
        "70": "Contact Card Issuer",
        "71": "PIN Not Changed",
        "75": "Allowable number of PIN tries exceeded",
        "76": "Invalid/nonexistent “To Account” specified",
        "77": "Invalid/nonexistent “From Account” specified",
        "78": "Invalid/nonexistent account specified (general)",
        "79": "Life cycle (Mastercard use only)",
        "80": "System not available",
        "81": "Domestic Debit Transaction Not Allowed (Regional use only)",
        "82": "Policy (Mastercard use only)",
        "83": "Fraud/Security (Mastercard use only)",
        "84": "Invalid Authorization Life Cycle",
        "85": "Not declined",
        "86": "PIN Validation not possible",
        "87": "Purchase Amount Only, No Cash Back Allowed",
        "88": "Cryptographic failure",
        "89": "Unacceptable PIN—Transaction Declined—Retry",
        "90": "Cutoff is in progress",
        "91": "Authorization System or issuer system inoperative",
        "92": "Unable to route transaction",
        "94": "Duplication transaction detected",
        // More?
    };

    // Grid options
    const gridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    const [columnDefs] = useState([
        { headerName: "Id", field: "id", hide: true },
        { headerName: 'Card Token', field: 'cardToken', width: 300, hide: true },
        {
            headerName: 'Name', field: 'fullName',
        },
        {
            headerName: 'Description', field: 'description', width: 300,
        },
        {
            headerName: 'Amount', field: 'amount', width: 300,
        },

        {
            headerName: 'Card Last 4', field: 'identifier', width: 125, cellClass: ['text-secondary'], filter: true,
        },
        {
            headerName: 'Last Used', field: 'lastUsed', width: 350, filter: true,
        },
    ]);

    const fetchData = useCallback(async () => {
        setError(null);
        try {
            const response = await getPaymentsByLastFour(searchTerm);
            console.log('Payments?: ' + JSON.stringify(response));
            setRowData(response);

            console.log('Row Data: ' + JSON.stringify(rowData));

            setShowGrid(true);
            if (gridRef.current && gridRef.current.api) {
                gridRef.current.api.setQuickFilter(searchTerm);
            }

        } catch (err) {
            setError('Failed to fetch payments. Please try again.');
            console.error('Error fetching data:', err);
        }
    }, [searchTerm, rowData]);

    useEffect(() => {
        SetDocTitle('Payment');
        SetPageTitle('Payment');
        function setUserFields() {
            const userData = JSON.parse(localStorage.getItem('userData'));
            var cUserId = 0;
            //console.log('User userData: ' + JSON.stringify(userData));
            //console.log('User userData - UserId: ' + userData.userId);
            if (userData) {
                cUserId = userData.userId;
                setUserId(userData.userId);
            }
            return cUserId;
        }
        if (!userData) {
            setUserFields();
        }

        // Session Data from PaymentModal
        const sessionData = sessionStorage.getItem('paymentData');
        //const sessionData = invoices;
        if (sessionData) {
            console.log('Invoices: ' + JSON.stringify(sessionData));

            try {
                const parsedData = JSON.parse(sessionData);
                console.log('parsedData: ' + JSON.stringify(parsedData));
                setReceivedData(parsedData);
                console.log('Session data:', parsedData);
                sessionStorage.removeItem('paymentData');

                setPayments(JSON.parse(parsedData.payments));
                setTotalPaymentAmount(parsedData.totalPaymentAmount);
                setTotalDue(parsedData.totalDue);
                setAmount(parsedData.totalPaymentAmount);
                setCash(parsedData.totalPaymentAmount);
                setCheckAmount(parsedData.totalPaymentAmount);
                setDepositRegister(parsedData.selectedDepositRegister);
                setPaymentType(parsedData.paymentType);
                setOrderInfo(parsedData.orderInfo);
                calculateChange(parsedData.totalDue, parsedData.totalPaymentAmount);

                // Payment Type
                switch (parsedData.paymentType) {
                    case "2":
                        console.log('check');
                        setActiveTab('check');
                        setPaymentType('check');
                        break;
                    case "3":
                        console.log('ach');
                        setActiveTab('ach');
                        setPaymentType('ach');
                        break;
                    case "1":
                        console.log('cash');
                        setActiveTab('cash');
                        setPaymentType('cash');
                        break;
                    case "4":
                        console.log('swipe');
                        setActiveTab('swipe');
                        setPaymentType('swipe');
                        break;
                    default:
                        console.log('newCard ', parsedData.paymentType);
                        setActiveTab('newCard');
                        setPaymentType('card');
                        break;
                }
                // Extract order IDs for Description
                var formattedDescription = JSON.parse(parsedData.payments)
                    .map(payment => `Invoices #${payment.InvoiceId}`)
                    .join(', ') + ' : ';
                setDescription(formattedDescription);

                // Remove session data
                sessionStorage.removeItem('paymentData');
            } catch (error) {
                console.error('Error parsing session:', error);
            }
        }

        const getCurrentDate = () => {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            setFormattedDate(month + "/" + day + "/" + year);
            setToday(formattedDate);
        };
        if (!today) {
            getCurrentDate();
        }

        fetchDepositRegisters();
    }, []);


    // C9G Response Message
    const getResponseMessage = (code) => {
        return responseCodeMessages[code] || "Unknown error";
    };

    // EXISTING CARD
    const handleExistingCardSubmit = async (e) => {
        e.preventDefault();
        //debugger;
        setError('');
        setSuccess('');
        const response = await makePaymentByToken(selectedRow.token, amount);
        console.log('Token payment response: ' + JSON.stringify(response));

        // TODO: SET FIELDS IN FORM
        //setFullName(response.)
        setSuccess('Card Payment Submitted successfully');
    }

    const handleSaveCard = (event) => {
        setSaveCard(event.target.checked);
    };

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRow(selectedRows.length > 0 ? selectedRows[0] : null);
        setFullName(selectedRows[0].fullName);
        console.log('Full Name: ' + selectedRows[0].fullName);
    }, []);

    const handleSearch = () => {
        if (searchTerm.trim()) {
            fetchData();
        }
    };

    // Handle enter on search box
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const cleanCardNumber = (card) => {
        var cleanedNumber = card.replace(/\D/g, '');
        return cleanedNumber;
    };

    const cleanCardDate = (card) => {
        var cleanedNumber = card.replace(/\D/g, '');
        return cleanedNumber;
    };

    const handleCashTenderedChange = (total, cash) => {
        setCash(cash);
        calculateChange(total, cash);
    };

    const calculateChange = (total, cash) => {
        console.log('Calculating change:', total, cash);
        const totalValue = parseFloat(total);
        const cashValue = parseFloat(cash);

        if (isNaN(totalValue) || isNaN(cashValue)) {
            setChangeDue('');
            setError('');
            return;
        }
        const newBalance = (balanceDue - cashValue).toFixed(2);
        console.log('Total:', totalValue);
        console.log('Cash:', cashValue);
        console.log('New Balance:', newBalance);
        if (newBalance < 0) {
            setBalanceDue(0);
            setChangeDue((newBalance - cashValue).toFixed(2) * -1)
        } else {
            setBalanceDue(newBalance);
            setChangeDue(0);
            setError('');
        }
    };

    const fetchDepositRegisters = async () => {
        try {
            const response = await get('depositregisters/GetDepositRegisters');
            if (Array.isArray(response)) {
                setDepositRegisters(response);
            } else if (response && response.$values) {
                setDepositRegisters(response.$values);
            } else {
                console.error('Error:', response);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleExpiryChange = (e) => {
        let input = e.target.value.replace(/\D/g, ''); // Remove non-digits
        let formattedInput = '';

        if (input.length > 0) {
            // Format as MM
            formattedInput = input.slice(0, 2);

            if (input.length > 2) {
                // Add slash and format as MM/YY
                formattedInput += '/' + input.slice(2, 4);
            }
        }

        // MM/YYYY format
        if (formattedInput.length <= 7) {
            setCardExpiry(formattedInput);
        }
    };

    const changeTabs = (tab) => {
        setError('');
        setSuccess('');
        setActiveTab(tab);
    }

    // PROCESS PAYMENT
    const processPayment = async (type, paymentData, userData) => {
        let token = '';
        let paymentResponse;
        console.log('Processing ' + paymentType + ' payment:', paymentData);
        try {
            if (type === 'Existing') {

            }
            else if (type === 'Card') {  // New Card
                // Get card token
                if (paymentData.saveCard) {
                    const tokenRequest = {
                        "TransType": "CreateCardToken",
                        "Medium": "Credit",
                        "EntryMode": "Manual",
                        "AccountNum": cleanCardNumber(paymentData.cardNumber),
                        "ExpDate": cleanCardDate(paymentData.cardExpiry),
                    };
                    const newToken = await getCardToken(tokenRequest);
                    token = newToken.CardToken;
                }

                // Make C9G Card payment
                const paymentRequest = {
                    "TransType": "Sale",
                    "Medium": "Credit",
                    "EntryMode": "Manual",
                    "AccountNum": cleanCardNumber(paymentData.cardNumber),
                    "ExpDate": cleanCardDate(paymentData.cardExpiry),
                    "MainAmt": paymentData.amount,
                    "InvoiceNum": paymentData.orderNumber,
                    "DepositRegister": paymentData.depositRegister,
                };
                paymentResponse = await makePayment(paymentRequest);
            } else if (type === 'ACH') { // ACH Payment
                const achTokenRequest = {
                    "accountNum": paymentData.accountNumber,
                    "routingNumber": paymentData.routingNumber,
                    "accountType": paymentData.accountType
                };

                // Get ACH token
                const achTokenResponse = await getAchToken(achTokenRequest);
                token = achTokenResponse.CardToken;

                // Make C9G ACH payment
                const achPaymentRequest = {
                    "CardToken": token,
                    "DepositRegister": paymentData.depositRegister,
                    "MainAmt": paymentData.amount,
                    "OrderNumber": paymentData.orderNumber,
                    "OrderId": paymentData.orderId,
                    "Description": paymentData.description || "ACH Payment",
                    "PaymentType": "ACH",
                };
                paymentResponse = await makeAchPayment(achPaymentRequest);
                // Check payment
            } else if (type === 'Check') {

                // Cash payment
            } else if (type === 'Cash') {

                // Swipe payment
            } else if (type === 'Swipe') {

            } else {
                throw new Error('Unsupported payment type: ' + paymentType);
            }

            //console.log(`${paymentType} Payment Response:`, JSON.stringify(paymentResponse));

            // Post payments and transactions to database
            for (const payment of paymentData.payments) {
                const paymentInfo = {
                    description: paymentData.description || '',
                    paymentType: paymentType,
                    fullName: paymentData.fullName || '',
                    amount: formatCurrencyDecimal(paymentData.totalAmount) || 0,
                    identifier: paymentType === 'Card' ? paymentData.cardNumber.slice(-4) : '',
                    accountNumber: paymentData.accountNumber || '',
                    depositRegister: paymentData.depositRegister,
                    checkNumber: paymentType === 'ACH' ? paymentData.checkNumber : '',
                    token: token,
                    billingGroupId: paymentData.billingGroupId || 0,
                    userId: userData.userId || 0,
                    invoices: paymentData.invoices // Add this line to include invoice information
                };

                console.log('Posting Payment Info to DB:', JSON.stringify(paymentInfo));

                const newPaymentId = await postPaymentToDatabase(paymentInfo);
                console.log('New PaymentID:', newPaymentId);

                // Only post transaction if not cash or check
                if (type !== 'Cash' && type !== 'Check' && type !== 'Swipe') {

                    const transactionInfo = {
                        transactionIdentifier: paymentResponse.transactionIdentifier || '',
                        transactionResponse: JSON.stringify(paymentResponse),
                        responseMessage: paymentResponse.errorText || paymentResponse.responseMessage || '',
                        cardToken: token,
                        cardZip: paymentData.cardZip || '',
                        status: paymentResponse.status || '',
                        transactionDate: new Date().toISOString(),
                        userId: userData.userId,
                        paymentId: newPaymentId,
                        invoiceId: payment.InvoiceId || 0,
                    };

                    console.log('Transaction Info:', JSON.stringify(transactionInfo));
                    const newTransaction = await postTransactionToDatabase(transactionInfo);
                    console.log('New Transaction:', JSON.stringify(newTransaction));
                }
            }

            return { success: true, message: `Payment successful` };
        } catch (error) {
            console.error('Operation failed:', error);
            return { success: false, message: error.response?.data?.message || error.message || 'An unknown error occurred' };
        }
    };

    const handlePaymentSubmit = async (e, type) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        let paymentData;
        if (type === 'Card') {
            paymentData = {
                saveCard,
                cardNumber,
                cardExpiry,
                paymentType: paymentType,
                amount,
                orderNumber,
                depositRegister,
                description,
                fullName,
                billingGroupId,
                //cardZip, // Is this needed for CG9 card payments, new or existing?
                payments,
                //invoiceId,
            };
        } else if (type === 'ACH') {
            if (accountNumber !== accountNumberConfirm) {
                setError("Account numbers don't match");
                return;
            }
            paymentData = {
                accountNumber,
                routingNumber,
                accountType,
                amount,
                orderNumber,
                orderId,
                depositRegister,
                paymentType: paymentType,
                description,
                fullName,
                billingGroupId,
                checkNumber,
                payments,
                //invoiceId,
            };
        } else if (type === 'Check' || type === 'Cash') {
            paymentData = {
                amount,
                orderNumber,
                depositRegister,
                paymentType,
                description,
                fullName,
                billingGroupId,
                checkNumber,
                payments,
                //invoiceId,
            };
        } else if (paymentType === 'Swipe') {
            // TODO: Handle swipe payment
        }

        paymentData = {
            ...paymentData,
            totalAmount: amount, // Total payment amount
            invoices: payments.map(payment => ({
                invoiceId: payment.InvoiceId,
                amountPaid: payment.PaymentAmount
            }))
        };

        console.log('Submitting ' + type + ' payment:', paymentData);

        const result = await processPayment(type, paymentData, userData);
        if (result.success) {
            //setSuccess(result.message);
            //navigate('/PostPayments', { state: { isPostPayments: true } });;
            //close();

            submitClose(result.message);
        } else {
            //setError("Error! " + result.message);
            console.error(result.message);
            submitClose(defaultErrMsg());
        }
    };

    return (
        <div className="modal-wrapper">
            <div className="modal-content !max-w-[1600px]">
                <div style={containerStyle} className="p-2 pt-0 ag-grid-center">
                    <div className="flex border-b">
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'newCard' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('newCard')}
                        >
                            New Credit Card Payment
                        </button>
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'existingCard' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('existingCard')}
                        >
                            Existing Credit Card Payment
                        </button>
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'ach' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('ach')}
                        >
                            ACH Payment
                        </button>
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'cash' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('cash')}
                        >
                            Cash Payment
                        </button>
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'check' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('check')}
                        >
                            Check Payment
                        </button>
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'swipe' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('swipe')}
                        >
                            Physical Card
                        </button>
                    </div>
                    <div className="p-4 flex-grow">

                        {/* NEW CARD */}
                        {activeTab === 'newCard' && (
                            <div>
                                {/*<h1 class="text-xl text-secondary font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">*/}
                                {/*    New Card Payment*/}
                                {/*</h1>*/}
                                <h3 className="text-m font-bold text-center">
                                    {/*Order #: {orderNumber}<br />*/}
                                    {/*Store #: {storeInfo} <br />*/}
                                    {/*Balance Due: ${orderInfo}<br />*/}
                                    Payment Date: {formattedDate}
                                </h3>
                                <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => handlePaymentSubmit(e, 'Card')}>
                                    <div>
                                        <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                                        <input type="name" name="fullName" id="fullName"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={fullName}
                                            placeholder=""
                                            onChange={(e) => setFullName(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="cardNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Card Number</label>
                                        <input type="number" name="cardNumber" id="cardNumber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="cardExpiry" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Expiration Date</label>
                                        <input
                                            type="text"
                                            name="cardExpiry"
                                            id="cardExpiry"
                                            placeholder="MM/YYYY"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required=""
                                            value={cardExpiry}
                                            onChange={handleExpiryChange}
                                        />
                                    </div>
                                    <div >
                                        <label htmlFor="cardCvv" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">CVV</label>
                                        <input type="number" name="cardCvv" id="cardCvv" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={cardCvv}
                                            onChange={(e) => setCardCvv(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment Description</label>
                                        <input type="text" name="desctiption" id="desctiption" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2">Deposit Register</label>
                                        <select
                                            value={depositRegister}
                                            onChange={(e) => setDepositRegister(e.target.value)}
                                            className="w-full p-2 border rounded"
                                        >
                                            <option value="">Select a register</option>
                                            {depositRegisters.map((register) => (
                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                    {register.registerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                        <input type="money" name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)} />
                                    </div>
                                    <div>
                                        <label className="lbl-cb w-1/3">
                                            <input id="saveCard" name="saveCard"
                                                className="inp-cb mark pl-5"
                                                type="checkbox"
                                                onChange={handleSaveCard}
                                            />
                                            Save Card
                                        </label>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Make Card Payment</button>
                                    <button onClick={close} className="btn btn-cancel">Back</button>
                                </form>
                            </div>
                        )}

                        {/* EXISTING */}
                        {activeTab === 'existingCard' && (
                            <div className="ag-theme-alpine ag-grid-center">
                                <h3 className="text-m font-bold text-center">
                                    {/*Order #: {orderNumber}<br />*/}
                                    {/*Store #: {storeInfo} <br />*/}
                                    {/*Balance Due: ${orderInfo}<br />*/}
                                    Payment Date: {formattedDate}
                                </h3>
                                <div className="pt-2">
                                    <label htmlFor="search"
                                        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                                        Filter
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                            </svg>
                                        </div>
                                        <input id="search" type="search" placeholder="Search last 4 digits ..." required
                                            className="w-2/5 block p-2 mb-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg 
                                focus:ring-blue-500 focus:border-blue-500 w-1/12"
                                            ref={inputRef}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            maxLength="4"
                                            onKeyPress={handleKeyPress}
                                            style={{ marginRight: '10px' }}
                                        />
                                        <div id="selectedRows" />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                                    <div style={{ height: '25%', display: 'flex', flexDirection: 'column', padding: '10px' }}>

                                        {showGrid && (
                                            <div style={{ flexGrow: 1, width: '100%' }}>

                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={columnDefs}
                                                    rowSelection={'single'}
                                                    onSelectionChanged={onSelectionChanged}
                                                    gridOptions={gridOptions}
                                                    onGridReady={(params) => {
                                                        params.api.sizeColumnsToFit();
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div style={{ height: '75%', overflowY: 'auto', padding: '10px' }}>
                                        {selectedRow && rowData ? (
                                            <div className="selected-row-info">
                                                <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => handlePaymentSubmit(e, 'Existing')}>
                                                    <div>
                                                        <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                                                        <input type="name" name="fullName" id="fullName"
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                                            value={fullName}
                                                            placeholder=""
                                                            readOnly />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment Description</label>
                                                        <input type="text" name="desctiption" id="desctiption" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)} />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="block mb-2">Deposit Register</label>
                                                        <select
                                                            value={depositRegister}
                                                            onChange={(e) => setDepositRegister(e.target.value)}
                                                            className="w-full p-2 border rounded"
                                                        >
                                                            <option value="">Select a register</option>
                                                            {depositRegisters.map((register) => (
                                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                                    {register.registerName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                                        <input type="money" name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)} />
                                                    </div>
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-start">

                                                        </div>
                                                    </div>

                                                    <button type="submit" className="w-full bg-primary bg-blue-600 text-white hover:text-white; focus:ring-4 focus:outline-none focus:text-white-400 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Make Existing Payment</button>
                                                </form>
                                            </div>
                                        ) : (
                                            <p>Select a row to make a payment.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* ACH */}
                        {activeTab === 'ach' && (
                            <div>
                                <h3 className="text-m font-bold text-center">
                                    {/*Order #: {orderNumber}<br />*/}
                                    {/*Store #: {storeInfo} <br />*/}
                                    {/*Balance Due: ${orderInfo}<br />*/}
                                    Payment Date: {formattedDate}
                                </h3>
                                <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => handlePaymentSubmit(e, 'ACH')}>
                                    <div>
                                        <label htmlFor="accountName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account Name</label>
                                        <input type="text" name="accountName" id="accountName"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={accountName}
                                            placeholder="Account Holder Name"
                                            onChange={(e) => setAccountName(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="accountNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account Number</label>
                                        <input type="number" name="accountNumber" id="accountNumber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={accountNumber}
                                            onChange={(e) => setAccountNumber(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="accountNumberConfirm" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Account Number</label>
                                        <input type="text" name="accountNumberConfirm" id="accountNumberConfirm" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={accountNumberConfirm}
                                            onChange={(e) => setAccountNumberConfirm(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="routingNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Routing Number</label>
                                        <input type="number" name="routingNumber" id="routingNumber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={routingNumber}
                                            onChange={(e) => setRoutingNumber(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="bankName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank Name</label>
                                        <input type="text" name="bankName" id="bankName" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={bankName}
                                            onChange={(e) => setBankName(e.target.value)} />
                                    </div>
                                    <div className="details-form-field-sel1">
                                        <label htmlFor="accountType" className="lbl-text1 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account Type:</label>
                                        <select id="accountType" name="accountType" className="sel-dd mark bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={accountType}
                                            onChange={(e) => setAccountType(e.target.value)}
                                        >
                                            <option>Checking</option>
                                            <option>Savings</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="bankAddress" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank Address</label>
                                        <input type="text" name="bankAddress" id="amount" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={bankAddress}
                                            onChange={(e) => setBankAddress(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="bankCity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank City</label>
                                        <input type="text" name="bankAddress" id="bankCity" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={bankCity}
                                            onChange={(e) => setBankCity(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="bankState" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank State</label>
                                        <input type="text" name="bankState" id="bankState" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={bankState}
                                            onChange={(e) => setBankState(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="bankZip" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank Zip</label>
                                        <input type="number" name="bankZip" id="bankZip" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={bankZip}
                                            onChange={(e) => setBankZip(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="bankPhone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bank Phone</label>
                                        <input type="text" name="bankPhone" id="bankPhone" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={bankPhone}
                                            onChange={(e) => setBankPhone(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                        <input type="number" name="amount" id="amount" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)} />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-start">

                                        </div>
                                    </div>
                                    {/*ADD SAVE CARD*/}
                                    <button type="submit" className="btn btn-primary">Submit ACH Payment</button>
                                    <button onClick={close} className="btn btn-cancel">Back</button>
                                </form>
                            </div>
                        )}

                        {/* CASH */}
                        {activeTab === 'cash' && (
                            <div>
                                <h3 className="text-m font-bold text-center">
                                    {/*Order #: {orderNumber}<br />*/}
                                    {/*Store #: {storeInfo} <br />*/}
                                    {/*Balance Due: ${orderInfo}<br />*/}
                                    Payment Date: {formattedDate}
                                </h3>
                                <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => handlePaymentSubmit(e, 'Cash')}>
                                    <div>
                                        <label htmlFor="totalDue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total Due</label>
                                        <input type="number" name="totalDue" id="totalDue" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={totalDue}
                                            readOnly />
                                    </div>
                                    <div>
                                        <label htmlFor="cash" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cash Tendered</label>
                                        <input type="number" name="cash" id="cash" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={cash}
                                            onChange={(e) => handleCashTenderedChange(totalDue, e.target.value)} />
                                    </div>
                                    {/*// ADD BALANCE*/}
                                    <div>
                                        <label htmlFor="balanceDue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Balance</label>
                                        <input type="number" name="balanceDue" id="balanceDue" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={balanceDue}
                                            readOnly />
                                    </div>
                                    <div>
                                        <label htmlFor="changeDue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Change Due</label>
                                        <input type="number" name="changeDue" id="changeDue" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={changeDue}
                                            readOnly />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit Cash Payment</button>
                                    <button onClick={close} className="btn btn-cancel">Back</button>
                                </form>
                            </div>
                        )}

                        {/* CHECK */}
                        {activeTab === 'check' && (
                            <div>
                                <h3 className="text-m font-bold text-center">
                                    {/*Order #: {orderNumber}<br />*/}
                                    {/*Store #: {storeInfo} <br />*/}
                                    {/*Balance Due: ${orderInfo}<br />*/}
                                    Payment Date: {formattedDate}
                                </h3>
                                <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => handlePaymentSubmit(e, 'Check')}>
                                    <div>
                                        <label htmlFor="totalDue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total Due</label>
                                        <input type="number" name="totalDue" id="totalDue" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            value={totalDue}
                                            readOnly
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="checkAmount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Check Amount</label>
                                        <input type="number" name="checkAmount" id="checkAmount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            placeholder=""
                                            value={checkAmount}
                                            onChange={(e) => setCheckAmount(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="checkNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Check Number</label>
                                        <input type="number" name="checkNumber" id="checkNumber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                            placeholder=""
                                            value={checkNumber}
                                            onChange={(e) => setCheckNumber(e.target.value)}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit Check Payment</button>
                                    <button onClick={close} className="btn btn-cancel">Back</button>
                                </form>
                            </div>
                        )}

                        {/* SWIPE */}
                        {activeTab === 'swipe' && (
                            <div>
                                <h3 className="text-m font-bold text-center">
                                    {/*Order #: {orderNumber}<br />*/}
                                    {/*Store #: {storeInfo} <br />*/}
                                    {/*Balance Due: ${orderInfo}<br />*/}
                                    Payment Date: {formattedDate}
                                </h3>
                                <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => handlePaymentSubmit(e, 'Swipe')}>
                                    <div>
                                        Swipe Card...
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit Swipe Payment</button>
                                    <button onClick={close} className="btn btn-cancel">Back</button>
                                </form>
                            </div>
                        )}

                        <div className="text-red p-4">{error}</div>
                        <div className="text-green text-bold p-4">{success}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}