import { Home } from "./components/Home";

import { OrderSearch } from "./components/OrderSearch/OrderSearch";
import { QuoteSearch } from "./components/OrderSearch/QuoteSearch";
import { ClaimsSearch } from "./components/ClaimsSearch/ClaimsSearch";

import { OrderParent } from "./components/Order/OrderParent";
import { OrderDetail } from "./components/Order/OrderDetail";

import { VendorsSearch } from "./components/Vendors/VendorsSearch";
import { VendorAddEdit } from "./components/Vendors/VendorAddEdit";
import { VendorParent } from "./components/Vendors/VendorParent";

import { AccountsSearch } from "./components/Account/AccountsSearch";
import { AccountParentCreate } from "./components/Account/AccountParentCreate";
import { AccountParent } from "./components/Account/AccountParent";

import { StoresSearch } from "./components/Store/StoresSearch";
import { StoreAddEdit } from "./components/Store/StoreAddEdit";

import { UsersSearch } from "./components/User/UsersSearch";
import { UserAddEdit } from "./components/User/UserAddEdit";

import { UserLogin } from "./components/Login/UserLogin";

import { RedirectHelper } from "./components/_reactHelpers/RedirectHelper";

import { ProductSalesSearch } from "./components/ProductSalesSearch/ProductSalesSearch";
import { ProductAdminSearch } from "./components/ProductAdminSearch/ProductAdminSearch";
import { ProductPricingSearch } from "./components/ProductPricing/ProductPricingSearch";
import { ProductPricingExportSearch } from "./components/ProductPricing/ProductPricingExportSearch";

import { EdiImportsSearch } from "./components/Import/EdiImportsSearch";
import { PcvpSearch } from "./components/ProductCategoryVendorRates/PcvpSearch";

//import { ProductAddEdit } from "./components/Products/ProductAddEdit";

import { ReceivingSearch } from "./components/WarehouseReceiving/ReceivingSearch";
import { PickupSearch } from "./components/WarehousePickup/PickupSearch";

import { SampleCheckoutSearch } from "./components/SampleCheckout/SampleCheckoutSearch";
import { SampleCheckoutParent } from "./components/SampleCheckout/SampleCheckoutParent";

import { PaymentForm } from "./components/Payment/PaymentForm";
import { Tax } from "./components/Tax/Tax";

import { SearchPayables } from "./components/Bills/SearchPayables";
import { ReadyForPayment } from "./components/Bills/ReadyForPayment";
import { ProductCodes } from "./components/ProductCategory/ProductCodes";
import { PayablesAgingReport } from "./components/Bills/PayablesAgingReport";

import { PendingPayments } from "./components/Accounting/CheckPayments/PendingPayments";
import { AchPendingPayments } from "./components/Accounting/CheckPayments/AchPendingPayments";
import { PrintCheckQueue } from "./components/Accounting/PrintCheckQueue/PrintCheckQueue";

import { BankAccounts } from "./components/Accounting/BankAccounts";
import { BankRegisters } from "./components/Accounting/BankRegisters";

import { ChartOfAccounts } from "./components/Accounting/ChartOfAccounts/ChartOfAccounts";
import { StoreAllocations } from "./components/Accounting/StoreAllocations";

import { PaymentHistory } from "./components/Accounting/PaymentHistory";
import { Receivables } from "./components/Invoice/Receivables";
import { PostPayments } from "./components/Invoice/PostPayments";
import { AgingInvoiceReport } from "./components/Invoice/AgingInvoiceReport"; 
import { DepositRegisterReport } from "./components/DepositRegisters/DepositRegisterReport";

import { Deposit } from "./components/DepositRegisters/Deposit";

import { JournalEntries } from "./components/JournalEntries/JournalEntries";
import { JournalEntryLines } from "./components/JournalEntries/JournalEntryLines";

// TODO: remove if not needed - dev paths
import { SignatureTest } from "./components/Signature/SignatureTest";
import { PaymentReceiptsReport } from "./components/Payment/PaymentReceiptReport";


const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    // Orders
    {
        path: '/Orders',
        element: <OrderSearch />
    },    
    {
        path: '/Order',
        element: <OrderParent />
    },    
    {
        path: '/CreateOrder',
        element: <OrderDetail />,
    },
    {
        path: '/EditOrder',
        element: <OrderParent />,
    },
    // Quotes
    {
        path: '/Quotes',
        element: <QuoteSearch />
    },
    {
        path: '/Quote',
        element: <OrderParent />
    },
    {
        path: '/CreateQuote',
        element: <OrderDetail />,
    },
    {
        path: '/EditQuote',
        element: <OrderParent />,
    },
    // Claims
    {
        path: '/Claims',
        element: <ClaimsSearch />
    },
    {
        path: '/Claim',
        element: <OrderParent />
    },
    {
        path: '/CreateClaim',
        element: <OrderDetail />,
    },
    {
        path: '/EditClaim',
        element: <OrderParent />,
    },
    // Inventory
    //{
    //    path: '/Inventory',
    //    element: <ClaimsSearch />
    //},
    {
        path: '/InventoryOrder',
        element: <OrderParent />
    },
    {
        path: '/CreateInventoryOrder',
        element: <OrderDetail />,
    },
    {
        path: '/EditInventoryOrder',
        element: <OrderParent />,
    },
    // Vendors
    {
        path: '/Vendors',
        element: <VendorsSearch />
    },
    {
        path: '/CreateVendor',
        element: <VendorAddEdit />,
    },
    {
        path: '/EditVendor',
        element: <VendorParent />,
    },
    // Accounts
    {
        path: '/Accounts',
        element: <AccountsSearch />
    },
    {
        path: '/CreateAccount',
        element: <AccountParentCreate />
    },
    {
        path: '/EditAccount',
        element: <AccountParent />
    },
    // Stores
    {
        path: '/Stores',
        element: <StoresSearch />
    },
    {
        path: '/Store',
        element: <StoreAddEdit />
    },
    // Users
    {
        path: '/Users',
        element: <UsersSearch />
    },
    {
        path: '/User',
        element: <UserAddEdit />
    },  
    {
        path: '/Login',
        element: <UserLogin />
    },  
    // Redirect Helper
    {
        path: '/Redirect',
        element: <RedirectHelper />
    },
    // Product Pricing - Sales
    {
        path: '/ProductPricing',
        element: <ProductSalesSearch />
    },
    
    // Product Search - Admin
    {
        path: '/ProductSearch',
        element: <ProductAdminSearch />
    },
    // Product Pricing - Admin
    {
        path: '/ProductPricingAdmin',
        element: <ProductPricingSearch />
    },
    // Product Import/Export
    {
        path: '/ProductImportExport',
        element: <ProductPricingExportSearch />
    },
    // EDI Imports
    {
        path: '/EdiImports',
        element: <EdiImportsSearch />
    },
    // Vendor Category Rates
    {
        path: '/VendorCategoryRates',
        element: <PcvpSearch />
    },
    // Receiving
    {
        path: '/Receiving',
        element: <ReceivingSearch />
    },
    // SampleCheckout
    {
        path: '/SampleCheckout',
        element: <SampleCheckoutSearch />
    },
    {
        path: '/CreateSampleCheckout',
        element: <SampleCheckoutParent />
    },
    {
        path: '/ViewSampleCheckout',
        element: <SampleCheckoutParent />
    },
    {
        path: '/MaterialPickup',
        element: <PickupSearch />
    },
    {
        //path: '/Payment/:orderId',
        path: '/Payment',
        element: <PaymentForm />
    },
    {
        path: '/Tax',
        element: <Tax />
    },
    {
        path: '/ProductCategories',
        element: <ProductCodes />
    },
    {
        path: '/PendingPayments',
        element: <PendingPayments />
    },    
    {
        path: '/AchPendingPayments',
        element: <AchPendingPayments />
    },
    {
        path: '/PrintChecks',
        element: <PrintCheckQueue />
    },
    {
        path: '/Payables',
        element: <SearchPayables />
    },
    {
        path: '/ReadyForPayment',
        element: <ReadyForPayment />
    },
    //{
    //    path: '/DueBills',
    //    element: <DueBillsReport />
    //},
    {
        path: '/PayablesAgingReport',
        element: <PayablesAgingReport />
    },
    {
        path: '/BankAccounts',
        element: <BankAccounts />
    },
    {
        path: '/BankRegisters/:bankId',
        element: <BankRegisters />
    },
    {
        path: '/ChartOfAccounts',
        element: <ChartOfAccounts />
    },
    {
        path: '/StoreAllocations',
        element: <StoreAllocations />
    },
    {
        path: '/SearchReceipts',
        element: <PaymentHistory />
    },
    {
        path: '/Receivables',
        element: <Receivables />
    },
    {
        path: '/PostPayments',
        element: <PostPayments />
    },
    {
        path: '/DepositRegisters',
        element: <Deposit />
    },
    {
        path: '/JournalEntries',
        element: <JournalEntries />
    },
    {
        path: '/JournalEntryLines/:batchId',
        element: <JournalEntryLines />
    },
    {
        path: '/Signature',
        element: <SignatureTest />
    },
    { 
        path: '/AgingInvoiceReport',
        element: <AgingInvoiceReport />
    },
    {
        path: '/DepositRegisterReport',
        element: <DepositRegisterReport />
    }
    //{
    //    path: '/PaymentReceiptReport',
    //    element: <PaymentReceiptsReport />
    //}
];

export default AppRoutes;