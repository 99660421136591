import React, { useState, useEffect, useCallback, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { PaymentInvoiceDetailsModal } from './PaymentInvoiceDetailsModal';

import { get} from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';
import {
    AgCurrencyMxWd, AgDateMxWd, defaultErrMsg, GetDisplayCurrencyStr,
    GetDisplayDateStr, IdExists, NotEmpty
} from '../../js/formHelpers';

import { DateFilter } from '../_reactHelpers/DateFilter';
import { ShowHideError, ShowHideLoading } from '../_reactHelpers/ReactHtmlHelpers';

export const PaymentHistory = () => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Search Receipts");
    const [selectedPayment, setSelectedPayment] = useState(null);

    const [reportDate, setReportDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [payments, setPayments] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const openModal = useCallback((paymentData) => {
        if (paymentData) {
            setSelectedPayment(paymentData);
        }
    }, []);

    const [columnDefs] = useState([
        { field: 'paymentId', headerName: 'Payment ID', },
        {
            field: 'paymentDate', headerName: 'Date Paid', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value),
        },
        { field: 'description', headerName: 'Description', },
        //{ field: 'invoiceNumber', headerName: 'Invoice #', },
        { field: 'accountName', headerName: 'Account Name' },
        {
            field: 'amount', headerName: 'Amount Paid', maxWidth: AgCurrencyMxWd(),
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', maxWidth: 130, sortable: false,
            cellRenderer: params => (
                <button onClick={() => openModal(params.data)}
                    className="btn-grid">View Receipts</button>
            )
        }
    ]);

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            enableRangeSelection: true
        }
    };

    const fetchPaymentsByDate = async () => {
        setLoading(true);
        setError('');

        try {
            const response = await get('/payments/GetPaymentsByDateRange', {
                params: { startDate, endDate }
            });

            var data = NotEmpty(response) ? response : [];
            setPayments(data);

        } catch (error) {
            setError(defaultErrMsg());
            setPayments([]);
        }
        finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        SetPageTitle("Search Receipts");
    }, []);

    if (loading) {
        return (<ShowHideLoading show={loading}></ShowHideLoading>);
    }
    if (error) {
        return (<ShowHideError msg={error}></ShowHideError>);
    }

    return (
        <div className="page-wrapper">            
            <div className="flex-wrapper">
                <div>
                    <DateFilter fromDate={startDate} setFromDate={setStartDate} fromDateStr="Start Date Paid:"
                        toDate={endDate} setToDate={setEndDate} toDateStr="End Date Paid:" enterFunction={fetchPaymentsByDate} />

                    <button className="btn-submit" onClick={fetchPaymentsByDate}>
                        Search Payment Receipts
                    </button>
                </div>
            </div>

            <div className="ag-theme-alpine ag-grid-act" style={{ height: '600px' }}>
                <AgGridReact
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    rowData={payments}
                />
            </div>

            {selectedPayment && (
                <PaymentInvoiceDetailsModal
                    paymentData={selectedPayment}
                    onClose={() => setSelectedPayment(null)}
                />
            )}
        </div>
    );
}