import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-daisyui';
import { initFlowbite } from "flowbite";

import { GetStoreSelectList, GetUserSelectList } from '../../js/dataHelpers.js';
import { IsFalse, IsTrue, PreventDefault } from '../../js/formHelpers.js';
import { ClickById } from '../../js/helpers.js';

export const SearchFilters = ({ isOpen, clearFilters, onClose, isProductPricingType,
    isOrderType, isQuoteType, isClaimType, isAccountType, isVendorType  }) => {

    // functions
    const propsClearFilters = clearFilters ?? null;
    const propsOnClose = onClose ?? null;

    // fields
    const propsIsOrder = isOrderType ?? false;
    const propsIsQuote = isQuoteType ?? false;
    const propsIsClaim = isClaimType ?? false;
    const propsIsAccount = isAccountType ?? false;
    const propsIsVendor = isVendorType ?? false;
    const propsIsProductPricingType = isProductPricingType ?? false;

    const typeStr = getTypeStr();

    const [userSp1List, setUserSp1List] = useState([]);
    const [storeList1, setStoreList1] = useState([]);
    const [storeList2, setStoreList2] = useState([]);
    const [storeList3, setStoreList3] = useState([]);

    function getTypeStr() {
        if (IsTrue(propsIsOrder)) {
            return "Order";
        }
        else if (IsTrue(propsIsQuote)) {
            return "Quote";
        }
        else if ((propsIsClaim)) {
            return "Claim";
        }
        else if ((propsIsAccount)) {
            return "Account";
        }
        else if ((propsIsVendor)) {
            return "Vendor";
        }
        else if ((propsIsProductPricingType)) {
            return "Product";
        }
    }

    async function createUserSp1List() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserSp1List(list);
    }

    async function createStoreList() {
        var stList1 = [];
        var stList2 = [];
        var stList3 = [];

        var storeList = await GetStoreSelectList(1);
        var thirdIndex = storeList.length / 3;

        storeList.forEach((item, index) => {

            var cbId = "checkbox-item-" + (index + 1);
            if (index < thirdIndex) {
                stList1.push(
                    <li key={index + 1}>
                        <div class='flex items-center p-2 rounded hover:bg-gray-100'>
                            <input id={cbId} type='checkbox' value={item.value}
                                className='cb-search-stores cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 
                                    border-gray-300 rounded focus:ring-blue-500 focus:ring-2'></input>
                            <label for={cbId} class='cursor-pointer w-full ml-2 text-sm font-medium text-gray-900 rounded'>
                                {item.text}
                            </label>
                        </div>
                    </li>
                );
            }
            else if (index > (thirdIndex) && index <= (thirdIndex * 2)) {
                stList2.push(
                    <li key={index + 1}>
                        <div class='flex items-center p-2 rounded hover:bg-gray-100'>
                            <input id={cbId} type='checkbox' value={item.value}
                                className='cb-search-stores cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 
                                    border-gray-300 rounded focus:ring-blue-500 focus:ring-2'></input>
                            <label for={cbId} class='cursor-pointer w-full ml-2 text-sm font-medium text-gray-900 rounded'>
                                {item.text}
                            </label>
                        </div>
                    </li>
                );
            }
            else {
                stList3.push(
                    <li key={index + 1}>
                        <div class='flex items-center p-2 rounded hover:bg-gray-100'>
                            <input id={cbId} type='checkbox' value={item.value}
                                className='cb-search-stores cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 
                                    border-gray-300 rounded focus:ring-blue-500 focus:ring-2'></input>
                            <label for={cbId} class='cursor-pointer w-full ml-2 text-sm font-medium text-gray-900 rounded'>
                                {item.text}
                            </label>
                        </div>
                    </li>
                );
            }
        });
        setStoreList1(stList1);
        setStoreList2(stList2);
        setStoreList3(stList3);
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            ClickById("btnSubmitFilterSearch");
        }
    }

    // ON LOAD
    useEffect(() => {
        initFlowbite();
        createUserSp1List();
        createStoreList();
    }, []);

    return (
        <Modal open={isOpen} className="order-filters-modal min-w-[550px] font-sans">
            <Button onClick={(e) => propsOnClose(e, false)}
                size="sm" color="ghost" shape="circle"
                className="absolute right-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
            >x</Button>
            <Modal.Header className="font-bold mb-0 text-center pb-2 text-secondary underline">
                Advanced Filters
            </Modal.Header>
            <Modal.Body>
                <div className="font-sans min-h-[300px] ">
                    <div>
                        {(IsTrue(propsIsOrder) || IsTrue(propsIsQuote) || IsTrue(propsIsClaim) ||
                            IsTrue(propsIsAccount) || IsTrue(propsIsVendor)) &&
                            /*< !--Store Dropdown -->*/
                            <div>
                                <div>
                                    <div className="order-filter-label">Stores:</div>
                                    <div className="order-filter pt-[10px] pb-[10px]">
                                        <button id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" type="button"
                                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white 
                                    bg-primary bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4
                                    focus:outline-none focus:ring-blue-300">
                                            Select
                                            <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                            </svg>
                                        </button>
                                        <div id="dropdownSearch" className="z-100 hidden bg-white rounded-lg shadow w-[390px]">
                                            <ul className="ul-store-dd table-cell" aria-labelledby="dropdownSearch">
                                                {storeList1}
                                            </ul>
                                            <ul className="ul-store-dd table-cell" aria-labelledby="dropdownSearch">
                                                {storeList2}
                                            </ul>
                                            <ul className="ul-store-dd table-cell" aria-labelledby="dropdownSearch">
                                                {storeList3}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/*< !--Salesperson1 Dropdown -->*/}
                                <div className="order-filter-row">
                                    <div className="order-filter-label">Salesperson:</div>
                                    <div className="order-filter">
                                        <select id="filterSp1Id" name="filterSp1Id" className="sp-sel1"
                                            onKeyDown={(e) => EnterSave(e)}
                                        >
                                            {userSp1List}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }

                        {(IsTrue(propsIsOrder) || IsTrue(propsIsQuote) || IsTrue(propsIsClaim)) &&
                        /*<!-- Created On -->*/
                            <div className="order-filter-row">
                                <div className="order-filter-label"><span>{typeStr} Date:</span></div>
                                <div className="order-filter">
                                    <input type="date" id="filterOrderDateFrom" name="filterOrderDateFrom"
                                        className="filterDate advFilterDate"
                                        onKeyDown={(e) => EnterSave(e)}></input>
                                </div>
                                <div className="order-filter-to">
                                    to
                                </div>
                                <div className="order-filter">
                                    <input type="date" id="filterOrderDateTo" name="filterOrderDateTo"
                                        className="filterDate advFilterDate"
                                        onKeyDown={(e) => EnterSave(e)}></input>
                                </div>
                            </div>
                        }

                        {(IsTrue(propsIsOrder) || IsTrue(propsIsClaim)) &&
                        /*<!-- Closed On -->*/
                            <div className="order-filter-row">
                                <div className="order-filter-label">Closed Date:</div>
                                <div className="order-filter">
                                    <input type="date" id="filterClosedFrom" name="filterClosedFrom"
                                        className="filterDate advFilterDate"
                                        onKeyDown={(e) => EnterSave(e)}></input>
                                </div>
                                <div className="order-filter-to">
                                    to
                                </div>
                                <div className="order-filter">
                                    <input type="date" id="filterClosedTo" name="filterClosedTo"
                                        className="filterDate advFilterDate"
                                        onKeyDown={(e) => EnterSave(e)}></input>
                                </div>
                            </div>
                        }

                        {(IsTrue(propsIsAccount) || IsTrue(propsIsVendor) || IsTrue(propsIsProductPricingType)) &&
                            /*<!-- Active / Inactive -->*/
                            <div>
                                <div className="flex-wrapper !justify-center">
                                    <div className="flex-wrapper mr-8 pr-4">
                                        <label className="font-bold pr-2">Active:</label>
                                        <input id="filterActive" type='checkbox' defaultChecked={true}
                                            className='cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 
                                                rounded focus:ring-blue-500 focus:ring-2'></input>
                                    </div>
                                    <div className="flex-wrapper">
                                        <label className="font-bold pr-2">Inactive:</label>
                                        <input id="filterInactive" type='checkbox' defaultChecked={false}
                                            className='cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 
                                                rounded focus:ring-blue-500 focus:ring-2'></input>
                                    </div>
                                </div>
                            </div>
                        }

                        {IsTrue(propsIsQuote) &&
                        /*<!-- Quotes Exported -->*/
                            <div>
                                {/* TODO: add FK to exported Order and look up dates */}

                                {/*<div className="order-filter-row">*/}
                                {/*    <div className="order-filter-label">Exported Date: </div>*/}
                                {/*    <div className="order-filter">*/}
                                {/*        <input type="date" id="filterQuoteExportedDateFrom" name="filterQuoteExportedDateFrom"*/}
                                {/*            className="filterDate advFilterDate" placeholder="Start Date"></input>*/}
                                {/*    </div>*/}
                                {/*    <div className="order-filter-to">*/}
                                {/*        to*/}
                                {/*    </div>*/}
                                {/*    <div className="order-filter">*/}
                                {/*        <input type="date" id="filterQuoteExportedDateTo" name="filterQuoteExportedDateTo"*/}
                                {/*            className="filterDate advFilterDate" placeholder="End Date"></input>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="order-filter-row">*/}
                                {/*    <div className="order-filter-label">Exported:</div>*/}
                                {/*    <div className="order-filter">*/}
                                {/*        <input id="filterQuoteExportedCb" type='checkbox' checked={false}*/}
                                {/*            className='cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 */}
                                {/*        border-gray-300 rounded focus:ring-blue-500 focus:ring-2'></input>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        }

                        {/*<!-- Received On -->*/}
                        {/*<div className="order-filter-row">*/}
                        {/*    <div className="order-filter-label">Received On: </div>*/}
                        {/*    <div className="order-filter">*/}
                        {/*        <input type="date" id="filterReceivedFrom" name="filterReceivedFrom"*/}
                        {/*            className="filterDate advFilterDate" placeholder="Start Date"></input>*/}
                        {/*    </div>*/}
                        {/*    <div className="order-filter-to">*/}
                        {/*        to*/}
                        {/*    </div>*/}
                        {/*    <div className="order-filter">*/}
                        {/*        <input type="date" id="filterReceivedTo" name="filterReceivedTo"*/}
                        {/*            className="filterDate advFilterDate" placeholder="End Date"></input>*/}
                        {/*    </div>*/}
                        {/*    */}{/*<div date-rangepicker className="flex items-center">*/}
                        {/*    */}{/*    <div className="relative">*/}
                        {/*    */}{/*        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
                        {/*    */}{/*            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">*/}
                        {/*    */}{/*                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />*/}
                        {/*    */}{/*            </svg>*/}
                        {/*    */}{/*        </div>*/}
                        {/*    */}{/*        <input name="start" type="text" placeholder="Order Date"*/}
                        {/*    */}{/*            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>*/}
                        {/*    */}{/*    </div>*/}
                        {/*    */}{/*    <span class="mx-4 text-gray-500">to</span>*/}
                        {/*    */}{/*    <div class="relative">*/}
                        {/*    */}{/*        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
                        {/*    */}{/*            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">*/}
                        {/*    */}{/*                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />*/}
                        {/*    */}{/*            </svg>*/}
                        {/*    */}{/*        </div>*/}
                        {/*    */}{/*        <input name="end" type="text" placeholder="Select End Date"*/}
                        {/*    */}{/*            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>*/}
                        {/*    */}{/*    </div>*/}
                        {/*    */}{/*</div>*/}
                        {/*</div>*/}

                        {/*<!-- Delivered On -->*/}
                        {/*<div className="order-filter-row">*/}
                        {/*    <div className="order-filter-label">Delivered On: </div>*/}
                        {/*    <div className="order-filter">*/}
                        {/*        <input type="date" id="filterDeliveredFrom" name="filterDeliveredFrom"*/}
                        {/*            className="filterDate advFilterDate" placeholder="Start Date"></input>*/}
                        {/*    </div>*/}
                        {/*    <div className="order-filter-to">*/}
                        {/*        to*/}
                        {/*    </div>*/}
                        {/*    <div className="order-filter">*/}
                        {/*        <input type="date" id="filterDeliveredTo" name="filterDeliveredTo"*/}
                        {/*            className="filterDate advFilterDate" placeholder="End Date"></input>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                </div>
            </Modal.Body>
            <Modal.Actions>
                {/*<Button className="btn-sm">Close</Button>*/}
                <Button onClick={(e) => propsClearFilters(e)} className="btn-close-modal">
                    Clear Filters
                </Button>
                <Button id="btnSubmitFilterSearch" onClick={(e) => propsOnClose(e, true)} className="btn-submit-modal">
                    Search {typeStr + "s"}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}