import React from 'react';
import {
    Exists, handleFieldChange, IsTrue, NotStringEmpty
} from '../../js/formHelpers';

export function DateFilter({ fromDate, setFromDate, fromDateStr, toDate, setToDate, toDateStr, enterFunction }) {

    const resetDates = () => {
        if (Exists(setFromDate)) {
            setFromDate('');
        }
        if (Exists(setToDate)) {
            setToDate('');
        }
    };

    function updateDate(e, fromDate) {
        var data = handleFieldChange(e);
        if (Exists(data)) {
            var date = data.value;
            if (IsTrue(fromDate)) {
                setFromDate(date);
            }
            else {
                setToDate(date);
            }
        }
    }

    function EnterSearch(e) {
        if (e.key === "Enter") {
            if (Exists(enterFunction)) {
                enterFunction();
            }
        }
    }

    return (
        <span className="">
            {(NotStringEmpty(fromDateStr) || NotStringEmpty(toDateStr)) &&
                <span>
                    {NotStringEmpty(fromDateStr) &&
                        <div className="table-cell pr-4">
                            <label className="date-filter-label pr-4">{fromDateStr}</label>
                            <input type="date" id="fromDate" className="date-filter-input"
                                value={fromDate}
                                onChange={(e) => updateDate(e, true)}
                                onKeyDown={(e) => EnterSearch(e)}
                            />
                        </div>
                    }
                    {NotStringEmpty(toDateStr) &&
                        <div className="table-cell">
                            <label className="date-filter-label">{toDateStr}</label>
                            <input type="date" id="toDate" className="date-filter-input"
                                value={toDate}
                                onChange={(e) => updateDate(e, false)}
                                onKeyDown={(e) => EnterSearch(e)}
                            />
                        </div>
                    }
                    <div className="table-cell pl-2">
                        <button onClick={resetDates} className="btn-close">Reset</button>
                    </div>
                </span>
            }
        </span>
    );
}