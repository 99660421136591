import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SetDocumentTitle, GetLocationStateId } from "../_reactHelpers/ReactHelpers";
import { GetEnumSelectList } from '../../js/dataHelpers.js';
import { SetPageTitle } from '../../js/helpers.js';
import {
    handleFieldChange, AddInputChangeListener, IsEmpty, enforcePhoneFormat, 
    formatToPhone, enforceZipFormat, enforceZipLength, ParseIntSafe, IdExists, NotEmpty, IsEmail, IsPhone, PreventDefault
} from '../../js/formHelpers.js';
import { get, post } from '../../services/apiService.js';

import { createVendorModel } from './_vendorHelpers.js';

export const VendorAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Vendor");
    const location = useLocation();
    const navigate = useNavigate();
    
    const payToCol = false;
    const [redirectSubmit, setRedirectSubmit] = useState(false);

    const [vendorTypeList, setVendorTypeList] = useState([]);
    const [vendorId, setVendorId] = useState(GetLocationStateId("vendorId"));
    const [vendorData, setVendorData] = useState(createVendorModel());

    const [validEmail1Hidden, setValidEmail1Hidden] = useState(" hidden ");
    const [validPhone1Hidden, setValidPhone1Hidden] = useState(" hidden ");
    const [validEmailRemitHidden, setValidEmailRemitHidden] = useState(" hidden ");

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState("");

    const createMsg = " created successfully.";
    const updateMsg = " updated successfully.";
    const createErrMsg = "There was an issue creating the vendor. Please try again or contact support.";
    const updateErrMsg = "There was an issue updating the vendor. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    /** Methods **/

    function backToClick(vendorStatus, vendorMessage) {
        navigate('/Vendors', {
            replace: true,
            state: { useSavedFiltersVendor: true, vendorStatus, vendorMessage }
        });
    }

    async function createVendorTypeList() {
        var list = [];

        var data = await GetEnumSelectList("VendorType");
        data.forEach((item, index) => {
            list.push(
                <option key={index} value={item.value}>{item.text}</option>
            );
        });
        setVendorTypeList(list);
    }

    const handleChange = (e) => {

        var data = handleFieldChange(e);
        if (data) {

            if (data.name === "zip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "payToZip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "phone1") {
                data.value = formatToPhone(e);
            }
            
            setVendorData((vendorData) => ({
                ...vendorData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(logMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function validateData() {
        var valid = true;

        // required
        if (IsEmpty(vendorData.name)) {
            valid = false;
        }
        // non-required
        if (NotEmpty(vendorData.phone1) && !IsPhone(vendorData.phone1)) {
            setValidPhone1Hidden("");
            valid = false;
        }
        if (NotEmpty(vendorData.email1) && !IsEmail(vendorData.email1)) {
            setValidEmail1Hidden("");
            valid = false;
        }
        if (NotEmpty(vendorData.remittanceEmail) && !IsEmail(vendorData.remittanceEmail)) {
            setValidEmailRemitHidden("");
            valid = false;
        }        
        return valid;
    }

    function hideValidationMessages() {
        updateMessage("", "");

        setValidPhone1Hidden(" hidden ");
        setValidEmail1Hidden(" hidden ");
        setValidEmailRemitHidden(" hidden ");
    }

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    };

    const handleSubmit = async (e) => {
        PreventDefault(e);
        hideValidationMessages();

        var valid = validateData();
        if (valid === true) {
            try {
                var model = createVendorModel(vendorData);
                //console.log(JSON.stringify(model));

                if (vendorId === 0) {
                    handlePost(model, true);
                }
                else if (vendorId > 0) {
                    handlePost(model, false);
                }
                else {
                    updateMessage(defaultErrMsg, "text-red", "null/invalid vendorId");
                }
            } catch (error) {
                updateMessage(createErrMsg, "text-red", error);
            }
        }
    };

    async function handlePost(model, create) {

        var vendorStatus = "update";
        var vendorMessage = vendorData.name + updateMsg;
        if (create === true) {
            vendorStatus = "create";
            vendorMessage = vendorData.name + createMsg;
        }

        var endpoint = "";
        if (create === true) {
            endpoint = 'vendors/VendorAdd';
        }
        else if (create === false) {
            endpoint = 'vendors/VendorUpdate';
        }

        if (endpoint && endpoint.length > 0) {
            try {
                const response = await post(endpoint, JSON.stringify(model));
                //console.log('Response: ' + JSON.stringify(response));

                var resMsg = response.status + ": " + response.statusText;
                if (create === true) {
                    updateMessage(createErrMsg, "text-red", resMsg);
                }
                else {
                    updateMessage(updateErrMsg, "text-red", resMsg);
                }
                const resVal = response;

                //on success return Id from post
                const vendorId = ParseIntSafe(resVal);
                if (IdExists(vendorId)) {
                    if (redirectSubmit) {
                        //redirect to Search on Update & Back
                        backToClick(vendorStatus, vendorMessage);
                    }
                    else {
                        if (create === true) {
                            //redirect to Update on successful Create
                            updateMessage(vendorMessage, "text-green");
                            setSubmitDisabled("true");

                            navigate('/EditVendor', { state: { vendorId, vendorStatus, vendorMessage } });
                        }
                        else {
                            updateMessage(vendorMessage, "text-green");
                        }
                    }
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null/empty apiUrl");
        }
    }

    function loadDataLists() {
        createVendorTypeList();
    }

    function showVendorMessage() {
        if (location.state && location.state.vendorStatus) {
            var status = location.state.vendorStatus;
            if (status === "create") {
                updateMessage("Vendor" + createMsg, "text-green");
            }
            else if (status === "update") {
                updateMessage("Vendor" + updateMsg, "text-green");
            }
        }
    }

    function addListener(baseId, changeId) {
        AddInputChangeListener(baseId, function () {
            setVendorData((vendorData) => ({
                ...vendorData,
                [changeId]: this.value
            }));
        });
    }

    function addListeners() {
        if (vendorId === 0) {
            addListener("name", "payToName");
            addListener("address1", "payToAddress1");
            addListener("address2", "payToAddress2");
            addListener("city", "payToCity");
            addListener("state", "payToState");
            addListener("zip", "payToZip");
        }
    };


    const handleLoadDetails = async () => {

        if (IdExists(vendorId)) {        
            const endpoint = 'vendors/GetVendor/' + vendorId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                setVendorData(createVendorModel(response));
                setDocTitle("Lumina Vendor - " + response.name);
                SetPageTitle("Vendor - ", response.name);
            }
            catch (error) {
                console.log('Error: ' + error);
            }
        }
        else {
            setDocTitle("Lumina Create Vendor");
            SetPageTitle("Create Vendor");
        }
    };


    // ON LOAD
    useEffect(() => {
        showVendorMessage();

        loadDataLists();
        addListeners();
        handleLoadDetails();
    }, []);

    return (
        <div className="details-page">
            <div class="details-page-container">
                <div>
                    {/*** Form ***/}
                    <form className="vend-form" onSubmit={handleSubmit}>

                        {/*** Form Fields ***/}
                        <div className="flex flex-wrap forms-page clear">
                            <div class="form-break">
                                <hr class="form-break-line" />
                                <span class="form-break-title">
                                    Vendor Info
                                </span>
                            </div>

                            <div className="form-col-1">

                                <div className="details-form-field">
                                    <label for="name" className="lbl-text1">Vendor Name:</label>
                                    <input className="inp-text mark" placeholder="" required
                                        id="name" name="name" type="text" 
                                        value={vendorData.name}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="name" className="lbl-text">Vendor Name</label>
                                </div>
                                <div className="details-form-field">
                                    <label for="address1" className="lbl-text1">Address1:</label>
                                    <input type="text" className="inp-text mark" placeholder="" required
                                        id="address1" name="address1"
                                        value={vendorData.address1}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="address1" className="lbl-text">Address1</label>
                                </div>
                                <div className="details-form-field">
                                    <label for="address2" className="lbl-text1">Address2:</label>
                                    <input type="text" className="inp-text mark" placeholder=""
                                        id="address2" name="address2"
                                        value={vendorData.address2}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="address2" className="lbl-text">Address2</label>
                                </div>
                                <div className="details-form-field">
                                    <div className="details-form-field !w-[55%] float-left">
                                        <label for="city" className="lbl-text1">City:</label>
                                        <input type="text" className="inp-text mark" placeholder="" required
                                            id="city" name="city"
                                            value={vendorData.city}
                                            onChange={handleChange}                                            
                                        />
                                        <label for="city" className="lbl-text">City</label>
                                    </div>
                                    <div className="details-form-field !w-[20%] float-left">
                                        <label for="state" className="lbl-text1 lbl-state-zip">State:</label>
                                        <input type="text" className="inp-text mark" placeholder="" required
                                            id="state" name="state"
                                            value={vendorData.state}
                                            onChange={handleChange}                                            
                                        />
                                        <label for="state" className="lbl-text">State</label>
                                    </div>
                                    <div className="details-form-field !w-[25%] float-left">
                                        <label for="zip" className="lbl-text1 lbl-state-zip">Zip:</label>
                                        <input type="text" className="inp-text mark" placeholder="" required
                                            id="zip" name="zip"
                                            onKeyDown={e => enforceZipFormat(e)}
                                            value={vendorData.zip}
                                            onChange={handleChange}                                            
                                        />
                                        <label for="zip" className="lbl-text">Zip</label>
                                    </div>
                                </div>

                                <div>
                                    {/*pay to single*/}
                                    {!payToCol &&
                                        <div>
                                            <div className="details-form-field !pt-2">
                                                <label for="payToName" className="lbl-text1">Pay to Name:</label>
                                                <input type="text" className="inp-text mark" placeholder=""
                                                    id="payToName" name="payToName"
                                                    value={vendorData.payToName}
                                                    onChange={handleChange}                                                    
                                                />
                                                <label for="payToName" className="lbl-text">Pay to Name</label>
                                            </div>

                                            <div className="details-form-field">
                                                <label for="payToAddress1" className="lbl-text1">Address:</label>
                                                <input type="text" className="inp-text mark" placeholder=""
                                                    id="payToAddress1" name="payToAddress1"
                                                    value={vendorData.payToAddress1}
                                                    onChange={handleChange}                                                    
                                                />
                                                <label for="payToAddress1" className="lbl-text">Address</label>
                                            </div>
                                            <div className="details-form-field">
                                                <label for="payToAddress2" className="lbl-text1">Address 2:</label>
                                                <input type="text" className="inp-text mark" placeholder=""
                                                    id="payToAddress2" name="payToAddress2"
                                                    value={vendorData.payToAddress2}
                                                    onChange={handleChange}                                                    
                                                />
                                                <label for="payToAddress2" className="lbl-text">Address 2</label>
                                            </div>
                                            <div className="details-form-field">
                                                <div className="details-form-field !w-[55%] float-left">
                                                    <label for="payToCity" className="lbl-text1">City:</label>
                                                    <input type="text" className="inp-text mark" placeholder=""
                                                        id="payToCity" name="payToCity"
                                                        value={vendorData.payToCity}
                                                        onChange={handleChange}                                                        
                                                    />
                                                    <label for="payToCity" className="lbl-text">City</label>
                                                </div>
                                                <div className="details-form-field !w-[20%] float-left">
                                                    <label for="payToState" className="lbl-text1 lbl-state-zip">State:</label>
                                                    <input type="text" className="inp-text mark" placeholder=""
                                                        id="payToState" name="payToState"
                                                        value={vendorData.payToState}
                                                        onChange={handleChange}                                                        
                                                    />
                                                    <label for="payToState" className="lbl-text">State</label>
                                                </div>
                                                <div className="details-form-field !w-[25%] float-left">
                                                    <label for="payToZip" className="lbl-text1 lbl-state-zip">Zip:</label>
                                                    <input type="text" className="inp-text mark" placeholder=""
                                                        id="payToZip" name="payToZip"
                                                        onKeyDown={e => enforceZipFormat(e)}
                                                        value={vendorData.payToZip}
                                                        onChange={handleChange}                                                        
                                                    />
                                                    <label for="payToZip" className="lbl-text">Zip Code</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="form-col-2">
                                {/*pay to single*/}
                                {!payToCol &&
                                    <div>
                                        <div className="details-form-field-sel1">
                                            <label for="vendorTypeId" className="lbl-text1">Type:</label>
                                            <select className="sel-dd mark"
                                                id="vendorTypeId" name="vendorTypeId"                                                
                                                value={vendorData.vendorTypeId}
                                                onChange={handleChange}
                                            >                                                
                                                {vendorTypeList}
                                            </select>
                                            <label for="vendorTypeId" className="lbl-sel-dd">Type</label>
                                        </div>
                                    </div>
                                }
                                {payToCol &&
                                    <div>
                                        <div className="details-form-field">
                                            <label for="payToName" className="lbl-text1">Pay to Name:</label>
                                            <input type="text" className="inp-text mark" placeholder=""
                                                id="payToName" name="payToName"
                                                value={vendorData.payToName}
                                                onChange={handleChange}                                                
                                            />
                                            <label for="payToName" className="lbl-text">Pay to Name</label>
                                        </div>

                                        <div className="details-form-field">
                                            <label for="payToAddress1" className="lbl-text1">Address:</label>
                                            <input type="text" className="inp-text mark" placeholder=""
                                                id="payToAddress1" name="payToAddress1"
                                                value={vendorData.payToAddress1}
                                                onChange={handleChange}                                                
                                            />
                                            <label for="payToAddress1" className="lbl-text">Address</label>
                                        </div>
                                        <div className="details-form-field">
                                            <label for="payToAddress2" className="lbl-text1">Address 2:</label>
                                            <input type="text" className="inp-text mark" placeholder=""
                                                id="payToAddress2" name="payToAddress2"
                                                value={vendorData.payToAddress2}
                                                onChange={handleChange}                                                
                                            />
                                            <label for="payToAddress2" className="lbl-text">Address 2</label>
                                        </div>
                                        <div className="details-form-field">
                                            <div className="details-form-field !w-3/5 float-left">
                                                <label for="payToCity" className="lbl-text1">City:</label>
                                                <input type="text" className="inp-text mark" placeholder=""
                                                    id="payToCity" name="payToCity"
                                                    value={vendorData.payToCity}
                                                    onChange={handleChange}                                                    
                                                />
                                                <label for="payToCity" className="lbl-text">City</label>
                                            </div>
                                            <div className="details-form-field !w-1/5 float-left">
                                                <label for="payToState" className="lbl-text1 lbl-state-zip">State:</label>
                                                <input type="text" className="inp-text mark" placeholder=""
                                                    id="payToState" name="payToState"
                                                    value={vendorData.payToState}
                                                    onChange={handleChange}                                                    
                                                />
                                                <label for="payToState" className="lbl-text">State</label>
                                            </div>
                                            <div className="details-form-field !w-1/5 float-left">
                                                <label for="payToZip" className="lbl-text1 lbl-state-zip">Zip:</label>
                                                <input type="text" className="inp-text mark" placeholder=""
                                                    id="payToZip" name="payToZip"
                                                    onKeyDown={e => enforceZipFormat(e)}
                                                    value={vendorData.payToZip}
                                                    onChange={handleChange}                                                    
                                                />
                                                <label for="payToZip" className="lbl-text">Zip Code</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="form-col-3">
                                {payToCol &&
                                    <div className="details-form-field-sel1">
                                        <label for="vendorTypeId" className="lbl-text1">Type:</label>
                                        <select className="sel-dd mark"
                                            id="vendorTypeId" name="vendorTypeId"                                            
                                            value={vendorData.vendorTypeId}
                                            onChange={handleChange}
                                        >
                                            <option value="0">None</option>
                                            <option value="1">Supplier</option>
                                            <option value="2">Account Commissions</option>
                                            <option value="3">Non-Material Supplier</option>
                                        </select>
                                        <label for="vendorTypeId" className="lbl-sel-dd">Type</label>
                                    </div>                                    
                                }
                                <div className="details-form-field">
                                    <label for="phone1" className="lbl-text1">Contact Phone:</label>
                                    <div className={"input-invalid-msg " + validPhone1Hidden}>
                                        *Invalid Phone
                                    </div>
                                    <input type="text" className="inp-text frmPhoneInput mark" placeholder=""
                                        id="phone1" name="phone1"                                         
                                        onKeyDown={e => enforcePhoneFormat(e)}
                                        value={vendorData.phone1}
                                        onChange={handleChange}
                                        
                                    />
                                    <label for="phone1" className="lbl-text">Contact Phone</label>
                                </div>
                                <div className="details-form-field">
                                    <label for="email1" className="lbl-text1">Email:</label>
                                    <div className={"input-invalid-msg " + validEmail1Hidden}>
                                        *Invalid Email
                                    </div>
                                    <input type="email" className="inp-text mark" placeholder="" 
                                        id="email1" name="email1" 
                                        value={vendorData.email1}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="email1" className="lbl-text">Email</label>
                                </div>
                            </div>

                            {/** Additional Info **/}
                            <div class="form-break">
                                <hr class="form-break-line" />
                                <span class="form-break-title">
                                    Additional Info
                                </span>
                            </div>

                            <div className="form-col-1">
                                <div className="details-form-field">
                                    <label for="accountNumber" className="lbl-text1 addlinfo-vend-lbl">
                                        Account # (CFM):
                                    </label>
                                    <input type="text" className="inp-text mark" placeholder=""
                                        id="accountNumber" name="accountNumber"
                                        value={vendorData.accountNumber}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="accountNumber" className="lbl-text">Account # (CFM)</label>
                                </div>                                
                                <div className="details-form-field">
                                    <label for="remittanceEmail" className="lbl-text1 addlinfo-vend-lbl">
                                        Remittance Email:
                                    </label>
                                    <div className={"input-invalid-msg " + validEmailRemitHidden}>
                                        *Invalid Email
                                    </div>
                                    <input type="email" className="inp-text mark" placeholder=""
                                        id="remittanceEmail" name="remittanceEmail"
                                        value={vendorData.remittanceEmail}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="remittanceEmail" className="lbl-text">Remittance Email</label>
                                </div>
                            </div>

                            <div className="form-col-2">
                                <div className="details-form-field">
                                    <label for="taxIdNumber" className="lbl-text1">TIN:</label>
                                    <input type="text" className="inp-text mark" placeholder=""
                                        id="taxIdNumber" name="taxIdNumber"
                                        value={vendorData.taxIdNumber}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="taxIdNumber" className="lbl-text">TIN</label>
                                </div>
                                <div className="details-form-field">
                                    <label for="termsDays" className="lbl-text1">Terms (Days):</label>
                                    <input type="number" className="inp-text mark" placeholder=""
                                        id="termsDays" name="termsDays"
                                        value={vendorData.termsDays}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="termsDays" className="lbl-text">Terms (Days)</label>
                                </div>
                                <div className="details-form-field">
                                    <label for="discountPercent" className="lbl-text1">Discount %:</label>
                                    <input type="number" className="inp-text mark" placeholder=""
                                        id="discountPercent" name="discountPercent"
                                        value={vendorData.discountPercent}
                                        onChange={handleChange}                                        
                                    />
                                    <label for="discountPercent" className="lbl-text">Discount %</label>
                                </div>
                                
                            </div>

                            <div className="form-col-3">
                                <div className="details-form-field-sel1">
                                    <label for="payStatusId" className="lbl-text1">Pay Status:</label>
                                    <select className="sel-dd mark"
                                        id="payStatusId" name="payStatusId"                                                                                
                                        value={vendorData.payStatusId}
                                        onChange={handleChange}
                                    >
                                        <option value="0">None</option>
                                        <option value="1">Ok to Pay</option>
                                        <option value="2">Hold</option>
                                    </select>
                                    <label for="payStatusId" className="lbl-sel-dd">Pay Status</label>
                                </div>

                                <div className="details-form-field pt-2">
                                    <div className="text-center font-bold !mt-[4px] table !w-full">
                                        <label className="lbl-cb w-1/2" for="is1099">
                                            1099
                                            <input type="checkbox" className="inp-cb"
                                                id="is1099" name="is1099"                                                 
                                                checked={vendorData.is1099}
                                                onChange={handleChange}                                                
                                            />
                                        </label>
                                        <label className="lbl-cb w-1/2" for="isElectronic1099">
                                            Electronic 1099
                                            <input type="checkbox" className="inp-cb mark"
                                                id="isElectronic1099" name="isElectronic1099"                                                 
                                                checked={vendorData.isElectronic1099}
                                                onChange={handleChange}                                                
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className="details-form-field">
                                    <div className="text-center font-bold !mt-[4px] table !w-full">
                                        <label className="lbl-cb w-1/2" for="isW9">
                                            W9
                                            <input type="checkbox" className="inp-cb mark"
                                                id="isW9" name="isW9"
                                                checked={vendorData.isW9}
                                                onChange={handleChange}                                                
                                            />
                                        </label>
                                        <label className="lbl-cb w-1/2" for="inactive">
                                            Inactive
                                            <input type="checkbox" className="inp-cb mark"
                                                id="inactive" name="inactive"
                                                checked={vendorData.inactive}
                                                onChange={handleChange}                                                
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mb-4">
                                <label for="notes" class="lbl-textarea">Import Notes:</label>
                                <div className="border-textarea">
                                    <textarea placeholder=" " className="inp-textarea markta"
                                        id="notes" name="notes" rows="3"
                                        value={vendorData.notes}
                                        onChange={handleChange}                                        
                                    ></textarea>
                                </div>
                            </div>

                            <div className="w-full text-right">
                                <div className={"inline-block mr-1 " + messageClass}>
                                    {message}
                                </div>
                                <div className="inline-block">
                                    {/*<button type="submit" className="btn-submit" disabled={submitDisabled}*/}
                                    {/*    onClick={(e) => btnSubmitClick(e, false)}>*/}
                                    {/*    {vendorId > 0 && "Save"}*/}
                                    {/*    {vendorId === 0 && "Save"}*/}
                                    {/*</button>*/}

                                    <button onClick={e => backToClick(null)} type="button" className="btn-cancel">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn-submit" disabled={submitDisabled}
                                        onClick={(e) => btnSubmitClick(e, true)}>
                                        Save & Close
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}