import React, { useState, useEffect, forwardRef } from 'react';

import { Checkbox, Table } from 'flowbite-react';
import CreatableSelect, {useCreatable} from 'react-select/creatable';

import { SetInputsCheckedByClass, SetTextContentById } from '../../js/helpers.js';
import { defaultErrMsg, Exists, IsEmail, IsEmpty, IsTrue, NotEmpty, NotStringEmpty, StringEmpty } from '../../js/formHelpers.js';
import { GreaterThan } from '../../js/calculationHelpers.js';

export function OrderLinesTable({ orderLines, type, pickCbsDisabled, useCbs }) {

    const cbAllClass = "cb-all-olt";
    const cbOlClass = "cb-ol-olt";
    
    const [allChecked, setAllChecked] = useState(false);
    const defaultAllChecked = type === "material-pickup";

    function cbAllClick() {
        var checked = !allChecked;
        SetInputsCheckedByClass(cbAllClass, checked);
        SetInputsCheckedByClass(cbOlClass, checked);
        setAllChecked(checked);
    }

    return (
        <div className="clear tbl-order-lines-div rounded border-gray-200 border">
            <Table className="tbl-order-lines">
                <Table.Head>
                    {useCbs === true &&
                        <Table.HeadCell className="">
                            <Checkbox className={"cursor-pointer " + cbAllClass}
                                onClick={cbAllClick} defaultChecked={defaultAllChecked}></Checkbox>
                        </Table.HeadCell>
                    }
                    {type === "receiving-modal" &&
                        <Table.HeadCell>#</Table.HeadCell>
                    }
                    <Table.HeadCell>Style</Table.HeadCell>
                    <Table.HeadCell>Color</Table.HeadCell> 
                    
                    {type === "receiving-modal" &&
                        <Table.HeadCell>PO Qty</Table.HeadCell>
                    }
                    <Table.HeadCell>Qty</Table.HeadCell>

                    {type === "receiving-modal" &&
                        <Table.HeadCell>Qty Rcvd</Table.HeadCell>
                    }

                    <Table.HeadCell>Units</Table.HeadCell>
                    <Table.HeadCell>Status</Table.HeadCell>
                    <Table.HeadCell>Date</Table.HeadCell>

                    {type === "material-pickup" &&
                        <Table.HeadCell>Qty Avail</Table.HeadCell>
                    }
                    <Table.HeadCell>Warehouse</Table.HeadCell>
                    <Table.HeadCell>Bin</Table.HeadCell>
                    <Table.HeadCell>Vendor</Table.HeadCell>
                    <Table.HeadCell>Category</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {getOrderLines()}
                </Table.Body>
            </Table>
        </div>
    );

    function getOrderLines() {
        var lineList = [];

        if (orderLines) {
            orderLines.forEach((ol, index) => {

                var cbId = "cb-ol-" + index;
                var cbClass = "cursor pointer ";
                var rowDisabled = false;
                var rowCssClass = "";
                var checked = false;
                var isStockChecked = false;
                var canDeliverStock = true;

                //set default if not passed
                if (pickCbsDisabled !== true) {
                    pickCbsDisabled = false;
                }

                //types: material-pickup, receiving-modal
                if (type === "material-pickup") {
                    //stock
                    isStockChecked = ol.isStock ?? false;
                    var stockQtyClass = "text-primary";
                    if (isStockChecked) {
                        if (ol.statusId === 15) {
                            if (ol.productStockModel.quantityAvailable < ol.quantity) {
                                stockQtyClass = "text-red";
                                canDeliverStock = false;
                                checked = false;
                            }
                        }
                        else {
                            if (ol.productStockModel.quantityAvailable < ol.quantityReceived) {
                                stockQtyClass = "text-red";
                                canDeliverStock = false;
                                checked = false;
                            }
                        }
                    }

                    //set up row actions
                    rowDisabled = (ol.statusId !== 3 && ol.statusId !== 15) || pickCbsDisabled || !canDeliverStock;
                    checked = rowDisabled === false;
                    rowCssClass = rowDisabled === false ? "bg-white" : "bg-gray-400/20";
                    cbId = "cb-mp-" + index;
                    cbClass = "";
                }
                else if (type === "receiving-modal") {
                    //add as needed
                }

                lineList.push(
                    <Table.Row key={index} className={rowCssClass}>

                        {useCbs === true &&
                            <Table.Cell className="p-4">
                                <Checkbox className={"cursor-pointer " + cbOlClass + " " + cbClass}
                                    id={cbId} value={ol.orderLineId} disabled={rowDisabled}
                                    defaultChecked={checked} />
                            </Table.Cell>
                        }
                        {type === "receiving-modal" &&
                            <Table.Cell>
                                <span className="text-primary">{ol.lineNumberStr}</span>
                            </Table.Cell>
                        }
                        <Table.Cell>
                            {ol.style}
                            {/*{ol.isRoll === true &&*/}
                            {/*    <div>*/}
                            {/*        <span className="pr-4px">Width: {ol.width} </span>*/}
                            {/*        <span>Height: {ol.height} </span>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </Table.Cell>
                        <Table.Cell>
                            {ol.color}
                        </Table.Cell>                        
                        {type === "receiving-modal" &&
                            <Table.Cell>
                                <span className="text-primary">{ol.poQuantity}</span>
                            </Table.Cell>
                        }
                        <Table.Cell>
                            <span className="text-primary">{ol.quantity}</span>
                        </Table.Cell>
                        {type === "receiving-modal" &&
                            <Table.Cell>
                                <span className="text-primary">{ol.quantityReceived}</span>
                            </Table.Cell>
                        }
                        <Table.Cell>
                            {ol.unitsStr}
                        </Table.Cell>
                        <Table.Cell>
                            <div className="w-[95px] text-primary">
                                {ol.statusStr}
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <div className="text-primary">
                                {ol.statusDateStr}
                            </div>
                        </Table.Cell>
                        {type === "material-pickup" &&
                            <Table.Cell className="text-left">
                                {ol.isStock &&
                                    <div className="ml-4 inline-block">
                                        {Exists(ol.productStockModel) &&
                                            <span className={stockQtyClass}>
                                                {ol.productStockModel.quantityAvailable}
                                            </span>
                                        }
                                        {!Exists(ol.productStockModel) &&
                                            <span className={stockQtyClass}>0</span>
                                        }
                                    </div>
                                }
                            </Table.Cell>
                        }
                        {/*{type === "material-pickup" &&*/}
                        {/*    <Table.Cell>*/}
                        {/*        {ol.isStock &&*/}
                        {/*            <div className="ml-7">*/}
                        {/*                {Exists(ol.productStockModel) &&*/}
                        {/*                    <span className="text-primary">*/}
                        {/*                        {ol.productStockModel.quantityAvailable}*/}
                        {/*                    </span>*/}
                        {/*                }*/}
                        {/*                {!Exists(ol.productStockModel) &&*/}
                        {/*                    <span className="text-primary">0</span>*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*    </Table.Cell>*/}
                        {/*}*/}
                        <Table.Cell>
                            {/* warehouse - location item delivered to, initially set from store */}
                            <span className="text-primary">{ol.warehouseName}</span>
                        </Table.Cell>
                        <Table.Cell>
                            {ol.bin}
                        </Table.Cell>
                        <Table.Cell>
                            {ol.vendorName}
                        </Table.Cell>
                        <Table.Cell>
                            {ol.productCategoryName}
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }
        return lineList;
    }
}


export const MultiSelectList = forwardRef(({ options, setDataFunc, updateMsgFunc, multiListRef }, ref) => {

    const [listOptions, setListOptions] = useState([]);
    const [value, setValue] = useState("");

    const defVal = "Add New...";

    const createOption = (label) => ({
        label: label.toLowerCase(),
        value: label.toLowerCase()
    });

    const selectbyIndex = (index) => {
        var opts = listOptions;
        if (NotEmpty[opts]) {
            //remove "Add New" option
            opts = opts.slice(1);
            if (opts.length > index) {
                var selectItem = listOptions[index];
                if (Exists(selectItem)) {
                    onChange(selectItem.value, "");
                }
            }
        }
    }

    const onChange = (value, context) => {
        if (updateMsgFunc) {
            updateMsgFunc("", "");
        }

        if (context.action === "clear") {
            //console.log(context.removedValues);

            if (NotEmpty(context.removedValues)) {
                var removedValue = context.removedValues[0].value;
                if (removedValue !== defVal) {
                    const newOptions = listOptions.filter(
                        (item) => item.value !== removedValue
                    );
                    addDefaultItem(listOptions);
                    setListOptions(newOptions);
                    setValue("");

                    if (setDataFunc) {
                        setDataFunc(newOptions);
                    }

                    if (updateMsgFunc) {
                        updateMsgFunc("Removed", "text-primary", "markch");
                    }
                }
            }
        }
        else {
            setValue(value)
        }
    }

    const handleCreate = (inputValue, email2) => {
        var msg = "Added";
        var cssClass = "text-primary";

        var isEmail = IsEmail(inputValue);
        if (isEmail === true) {

            if (NotEmpty(email2)) {
                const email2Opt = createOption(email2);
                listOptions.push(email2Opt);
            }
            
            const newOption = createOption(inputValue);
            listOptions.push(newOption);

            addDefaultItem(listOptions);
            setListOptions(listOptions);
            //setListOptions((prev) => [...prev, newOption]); 

            if (setDataFunc) {
                setDataFunc(listOptions);
            }
        }
        else {
            msg = "Invalid Email";
            cssClass = "text-red";
        }
        setValue("");
                
        if (updateMsgFunc) {
            updateMsgFunc(msg, cssClass, "");
        }
    }

    //const reloadList = (allOptions) => {
    //    var msg = "Added";
    //    var cssClass = "text-primary";

    //    addDefaultItem(allOptions);
    //    setListOptions(allOptions);
    //    setValue("");

    //    //if (setDataFunc) {
    //    //    setDataFunc(listOptions);
    //    //}

    //    //if (updateMsgFunc) {
    //    //    updateMsgFunc(msg, cssClass, "markch");
    //    //}
    //};

    async function addDefaultItem(opts) {
        var exists = [];
                
        if (NotEmpty(opts)) {
            exists = listOptions.filter((item) => item.value === defVal);
            if (IsEmpty(exists)) {
                opts.unshift({ label: defVal, value: defVal });
            }
        }
        else {
            opts = [{ label: defVal, value: defVal }];
        }

        setListOptions(opts);
        if (setDataFunc) {
            setDataFunc(opts);
        }
    }

    useEffect(() => {
        if (multiListRef && ref) {

            if (!multiListRef.handleCreate) {
                multiListRef.handleCreate = handleCreate;
            }
            //if (!multiListRef.reloadList) {
            //    multiListRef.reloadList = reloadList;
            //}
        }            
        
        addDefaultItem(options);

    }, []);

    return (
        <CreatableSelect
            isClearable
            onChange={onChange}
            selectbyIndex={selectbyIndex}
            onCreateOption={handleCreate}
            options={listOptions}
            value={value}            
            placeholder="Select or type to Add..."
            className="react-select-container cursor-text"
            classNamePrefix="react-select"
        />
    );
});

export function ShowHideLoading({ show }) {
    //if (IsTrue(show)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    //}
}

export function ShowHideError({ show, msg }) {
    //if (IsTrue(show)) {    
    if (StringEmpty(msg)) {
        msg = defaultErrMsg();
    }

    // TODO: check for true/false then string
    msg = defaultErrMsg();
    return <div className="text-red-500 text-center mt-[25%] text-xl">{msg}</div>;
    //}
}