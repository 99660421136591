import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/apiService';

export function EditDepositModal({ depositData, onClose, onUpdateDeposit }) {

    const [formData, setFormData] = useState({
        depositRegisterId: depositData.depositRegisterId,
        storeId: depositData.storeId,
        registerName: depositData.registerName,
        registerType: depositData.registerType,
        glAccountCode: depositData.glAccountCode
    });

    const [chartAccounts, setChartAccounts] = useState([]);
    const [stores, setStores] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchStores = async () => {        
        try {
            const response = await get("/store/GetAllStores");
            //console.log('Fetched stores:', response);

            if (response && Array.isArray(response.$values)) {
                setStores(response.$values.filter(store => !store.deleted));
            } else if (Array.isArray(response)) {
                setStores(response.filter(store => !store.deleted));
            } else {
                console.error('Unexpected store data format:', response);
                setError('Unexpected store data format. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching stores', error);
            setError('Failed to load stores. Please try again.');
        } finally {
        }
    };

    const fetchChartAccounts = async () => {
        try {
            const response = await get("/store/GetAllStores");
            const data = response.map((item) => ({
                ...item,
                id: item.chartAccountId,
            }));

            setChartAccounts(data);
        } catch (error) {
            console.error('Error fetching stores', error);
            setError('Failed to load stores. Please try again.');
        } finally {
        }
    };

    useEffect(() => {       
        fetchStores();
    }, []);

    useEffect(() => {
        if (stores.length > 0 && formData.storeId) {
            const selectedStore = stores.find(store => store.id === formData.storeId);
            if (!selectedStore) {
                console.warn(`No matching store found for storeId: ${formData.storeId}`);
            }
        }
    }, [stores, formData.storeId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(`Changing ${name} to:`, value);

        setFormData(prev => {
            let updatedValue = value;
            if (name === 'storeId') {
                updatedValue = value === '' ? '' : parseInt(value, 10);
            }
            const updatedData = {
                ...prev,
                [name]: updatedValue
            };
            console.log('Updated form data:', updatedData);
            return updatedData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            //console.log('Submitting form data:', formData);

            const response = await post(`/depositregisters/UpdateDepositRegister`, formData);
            console.log('Updated deposit register response:', response);

            onUpdateDeposit(response.data);
            onClose();
        } catch (err) {
            setError('Failed to update deposit register. Please try again.');
            console.error('Error updating deposit register:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Edit Deposit Register</h2>
            {error && <div className="text-red-500">{error}</div>}
            {/*<div>*/}
            {/*    <label htmlFor="registerId" className="block text-sm font-medium text-gray-700">Register ID:</label>*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        id="registerId"*/}
            {/*        name="registerId"*/}
            {/*        value={formData.registerId}*/}
            {/*        onChange={handleChange}*/}
            {/*        disabled*/}
            {/*        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-100"*/}
            {/*    />*/}
            {/*</div>*/}
            <div>
                <label htmlFor="storeId" className="block text-sm font-medium text-gray-700">Store:</label>
                <select
                    id="StoreId"
                    name="StoreId"
                    value={formData.storeId}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    <option value="">Select a store</option>
                    {stores.map(store => (
                        <option key={store.storeId || store.id} value={store.storeId || store.id}>
                            #{store.storeNumber} - {store.name || store.displayName}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label htmlFor="registerName" className="block text-sm font-medium text-gray-700">Register Name:</label>
                <input
                    type="text"
                    id="registerName"
                    name="registerName"
                    value={formData.registerName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div>
                <label htmlFor="registerType" className="block text-sm font-medium text-gray-700">Register Type:</label>
                <select
                    id="registerType"
                    name="registerType"
                    value={formData.registerType}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    <option value="Cash/Check">Cash/Check</option>
                    <option value="MC/Visa">MC/Visa</option>
                    <option value="AMEX">AMEX</option>
                </select>
            </div>
            <div>
                <label htmlFor="glAccountCode" className="block text-sm font-medium text-gray-700">GL Account Code:</label>
                <input
                    type="text"
                    id="glAccountCode"
                    name="glAccountCode"
                    value={formData.glAccountCode}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div className="flex justify-end space-x-2 mt-4">
                <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    disabled={loading}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                >
                    {loading ? 'Saving...' : 'Save Changes'}
                </button>
            </div>
        </form>
    );
}