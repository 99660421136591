import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { GetLocationStateId, SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { GetInputValuesCheckedByClass, NotStringContains, RemoveClassById, SetPageTitle } from '../../../js/helpers';
import {
    Exists, handleFieldChange, IdExists, IsEmpty,
    IsFalse,
    isSelectedDropdown,
    IsTrue, NotEmpty, NotStringEmpty, ParseIntSafe, PreventDefault, SetAndClickById, StringEmpty, SwitchFbTab
}
from '../../../js/formHelpers';
import {
    StandardDataAndResponsePOST, StandardIdAndResponseGET,
    GetProductCategorySelectList, GetStoreSelectList, GetVendorSelectList, GetUnitsSelectList
}
from '../../../js/dataHelpers';
import { formatNumberDecPlaces } from '../../../js/formHelpers';


export const ProductDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const propsOnCloseModal = props.onCloseModal ?? null;
    const propsProductData = props.productData ?? null;
    const propsProductMessage = props.productMessage ?? "";    
    const propsLoadTab = props.loadTab ?? "";    

    const propsPageType = props.pageType ?? "";
    const propsIsPricingPageType = props.isPricingPageType ?? false;;
    const propsIsAdminPageType = props.isAdminPageType ?? false;
    const propsIsSalesPageType = props.isSalesPageType ?? false;
    const propsIsOrderPageType = props.isOrderPageType ?? false;

    //const propsIsProductPageType = props.isProductPageType ?? false;
    //const propsIsOrderPageType = props.isOrderPageType ?? false;
    //const propsIsAdminRoleType = props.isAdminRoleType ?? false;
    //const propsIsSalesRoleType = props.isSalesRoleType ?? false;

    const [productId, setProductId] = useState(0);
    const [productData, setProductData] = useState(null);

    //const [storeList, setStoreList] = useState([]);
    //const [storeList1, setStoreList1] = useState([]);
    //const [storeList2, setStoreList2] = useState([]);
    //const [storeList3, setStoreList3] = useState([]);

    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const [requiredClass, setRequiredClass] = useState(" hidden ");

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Helpers

    function onCloseClick(msg, reloadId) {
        //PreventDefault(e);
        propsOnCloseModal(msg, reloadId);
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    //async function createStoreList() {
    //    var list = [];
    //    list.push(<option key="0" value="0">None</option>);

    //    var dataList = await GetStoreSelectList(0);
    //    dataList.forEach((item, index) => {
    //        list.push(
    //            <option key={index + 1} value={item.value}>{item.text}</option>
    //        );
    //    });
    //    setStoreList(list);
    //}

    //async function createStoreList(product) {
    //    var stList1 = [];
    //    var storeList = await GetStoreSelectList(0);

    //    var prodStoreIds = product.storeIds ?? [];

    //    storeList.forEach((item, index) => {

    //        var storeIdArr = prodStoreIds.filter(function (storeId) {
    //            return storeId === item.value;
    //        });

    //        var storeChecked = false;
    //        if (NotEmpty(storeIdArr) && storeIdArr[0] === item.value) {
    //            storeChecked = true;
    //        }

    //        var cbId = "checkbox-item-" + (index + 1);
    //            stList1.push(
    //                <li key={index + 1}>
    //                    <div class='p-2 rounded hover:bg-gray-100'>
    //                        <input id={cbId} type='checkbox' value={item.value} defaultChecked={storeChecked}
    //                            className='cb-product-stores cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 
    //                                border-gray-300 rounded focus:ring-blue-500 focus:ring-2'></input>
    //                        <label for={cbId} className='cursor-pointer w-full ml-2 text-sm font-medium !text-secondary rounded'>
    //                            {item.text}
    //                        </label>
    //                    </div>
    //                </li>
    //            );
    //    });
    //    setStoreList1(stList1);        
    //}

    async function createProductCategoryList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetProductCategorySelectList("order");
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setCategoryList(list);
    }

    async function createVendorSupplierList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetVendorSelectList(1);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setVendorList(list);
    }

    async function createUnitsList(units) {
        var list = [];
        list.push(<option key="0" value="">None</option>);

        var createForValue = NotStringEmpty(units);
        var dataList = await GetUnitsSelectList();

        var cnt = 0;
        dataList.forEach((item, index) => {
            list.push(<option key={cnt + 1} value={item.value}>{item.text}</option>);
            cnt++;

            if (IsTrue(createForValue)) {
                createForValue = NotStringContains(item.text, units);
            }
        });

        if (IsTrue(createForValue)) {
            list.push(<option key={cnt + 1} value={units}>{units}</option>);
        }

        setUnitsList(list);
    }

    // Methods

    const handleChange = (e) => {
        //updateMessage("", "");

        var data = handleFieldChange(e);
        if (data) {
            setProductData((productData) => ({
                ...productData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    // Validate and Post Data

    const validateData = () => {
        var valid = true;
        setRequiredClass(" hidden ");

        if (IsFalse(isSelectedDropdown(productData.categoryId))) {
            valid = false;
        }
        if (IsFalse(isSelectedDropdown(productData.vendorId))) {
            valid = false;
        }
        if (IsFalse(isSelectedDropdown(productData.units))) {
            valid = false;
        }
        if (StringEmpty(productData.style)) {
            valid = false;
        }

        if (IsFalse(valid)) {
            setRequiredClass("");
            updateMessage("*Required fields", "text-red");
        }
        return valid;
    };

    function saveMsg() {
        if (IsTrue(productData.productDeleted)) {
            return productData.style + " deleted successfully.";
        }
        else if (IdExists(productId)) {
            return productData.style + " updated successfully.";
        }
        else {
            return productData.style + " created successfully.";
        }
    }

    async function btnSubmitClick(e, close) {
        PreventDefault(e);

        var valid = validateData();
        if (IsTrue(valid)) {

            var create = !IdExists(productId);

            var resp = await SaveProductDetailsPOST();
            var iRespId = ParseIntSafe(resp);

            if (IdExists(iRespId)) {

                var msg = saveMsg();
                if (create) {
                    onCloseClick(msg, iRespId);
                }
                else {
                    onCloseClick(msg);
                }

                //if (IsTrue(close)) {
                //    onCloseClick(e, msg, iRespId);
                //}
                //else {
                //    updateMessage(msg, "text-green");
                //}
            }
            else {
                console.error("SaveProductDetailsPOST(): ", JSON.stringify(resp));
                updateMessage(defaultErrMsg, "text-red");
            }
        }
    };

    async function SaveProductDetailsPOST() {
        var response = null;

        var postModel = JSON.stringify(productData);
        var endpoint = "product/SaveProductDetails";
        var typeStr = "SaveProductDetailsPOST()";

        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        return response;
    }

    // Load Data

    const loadProductData = async () => {

        if (Exists(propsProductData) && Exists(propsProductData.product)) {

            if (NotStringEmpty(propsProductMessage)) {
                updateMessage(propsProductMessage, "text-green");
            }

            setProductId(propsProductData.product.productId);
            setProductData(propsProductData.product);

            createUnitsList(propsProductData.product.units);
            //createStoreList(propsProductData.product);

            if (propsIsSalesPageType || propsIsOrderPageType) {
                SwitchFbTab(null, "fb-tabs-btns", "prod-tab-content", "ProductBundles-tab", "ProductBundles");                
            }
            else {

                if (propsLoadTab === "skuColor") {
                    SwitchFbTab(null, "fb-tabs-btns", "prod-tab-content", "ProductSkus-tab", "ProductSkus");
                }
                else {
                    SwitchFbTab(null, "fb-tabs-btns", "prod-tab-content", "ProductDetails-tab", "ProductDetails");
                }
            }
            setLoading(false);
        }
        else {
            console.error("loadProductData - propsProductData is null")
            setError(true);
        }
    };

    function EnterSave(e) {
        if (e.key === "Enter") {
            btnSubmitClick(e, true);
        }
    }

    useEffect(() => {
      
        createVendorSupplierList();
        createProductCategoryList();

        loadProductData();
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(error))
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="details-page">
            <div class="details-page-container">
                {productData &&
                    <div>
                        <div className="act-form">
                            <div className="forms-page clear product-edit-modal-vh">
                                {/*<div className="form-break pb-2">*/}
                                {/*    <hr className="form-break-line" />*/}
                                {/*    <span className="form-break-title">*/}
                                {/*        Product Info*/}
                                {/*    </span>*/}
                                {/*</div>*/}

                                {/** column1 **/}
                                <div className="">
                                    <div className="form-col-1 inline-block !w-[60%] align-top pr-4">

                                        {/*<div className="details-form-field-sel1">*/}
                                        {/*    <label for="storeId" className="lbl-text1">Store</label>*/}
                                        {/*    <select id="storeId" name="storeId"*/}
                                        {/*        className="sp-sel" //disabled={customFieldsDisabled}  */}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.storeId}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*    >*/}
                                        {/*        {storeList}*/}
                                        {/*    </select>*/}
                                        {/*</div>*/}

                                        <div className="details-form-field-sel1">
                                            <label for="categoryId" className="lbl-text1">Category</label>
                                            <select id="categoryId" name="categoryId"
                                                className="sp-sel !w-auto" //disabled={customFieldsDisabled}                                                
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.categoryId}
                                                onChange={handleChange}
                                            >
                                                {categoryList}
                                            </select>
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>

                                        <div className="details-form-field-sel1">
                                            <label for="vendorId" className="lbl-text1">Vendor</label>
                                            <select id="vendorId" name="vendorId"
                                                className="sp-sel !w-auto" //disabled={customFieldsDisabled}                                                
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.vendorId}
                                                onChange={handleChange}
                                            >
                                                {vendorList}
                                            </select>
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>

                                        <div className="details-form-field pr-2">
                                            <label for="name" className="lbl-text1">Style</label>
                                            <input id="style" name="style" type="text"
                                                className="inp-text table-cell"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.style}
                                                onChange={handleChange}
                                                autoFocus
                                            />
                                            <span className={"text-red pl-1 table-cell " + requiredClass}>*</span>
                                        </div>

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="vendorStyle" className="lbl-text1">Vendor Style</label>*/}
                                        {/*    <input type="text"*/}
                                        {/*        id="vendorStyle" name="vendorStyle"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.vendorStyle}*/}
                                        {/*        //onChange={handleChange}*/}
                                        {/*        disabled*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="name" className="lbl-text1">Style Num</label>*/}
                                        {/*    <input id="style" name="style" type="text"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        value={productData.style}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="name" className="lbl-text1">Custom Style Num</label>*/}
                                        {/*    <input id="style" name="style" type="text"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        value={productData.style}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*        disabled*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        <div className="details-form-field">
                                            <label for="units" className="lbl-text1">Units</label>
                                            <select id="units" name="units"
                                                className="sp-sel !w-[100px]"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.units}
                                                onChange={handleChange}
                                            >
                                                {unitsList}
                                            </select>
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>

                                        <div className="details-form-field">
                                            <label for="manufacturerName" className="lbl-text1">Manufacturer</label>
                                            <input id="manufacturerName" name="manufacturerName" type="text"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.manufacturerName}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="details-form-field">
                                            <label for="collection" className="lbl-text1">Collection</label>
                                            <input id="collection" name="collection" type="text"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.collection}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="details-form-field">
                                            <label for="collection" className="lbl-text1">Notes</label>
                                            <div className="border-textarea">
                                                <textarea className="inp-textarea acct-notes-textarea !p-2" rows="6"
                                                    id="notes" name="notes"
                                                    value={productData.notes}
                                                    onChange={handleChange}>
                                                </textarea>
                                            </div>
                                        </div>

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="serialNo" className="lbl-text1">Info</label>*/}
                                        {/*    <input id="serialNo" name="serialNo" type="text"*/}
                                        {/*        className="inp-text" */}
                                        {/*        value={productData.serialNo}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*        disabled*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                    </div>

                                    {/** column2 **/}
                                    <div className="form-col-1 inline-block !w-[40%] align-top">

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="buyQuantity" className="lbl-text1">Buy in Multiples of</label>*/}
                                        {/*    <input id="buyQuantity" name="buyQuantity" type="number"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.buyQuantity}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="buyUnit" className="lbl-text1">Buy Unit</label>*/}
                                        {/*    <input id="buyUnit" name="buyUnit" type="text"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.buyUnit}*/}
                                        {/*        //onChange={handleChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        <div className="details-form-field">
                                            <label for="sellQuantity" className="lbl-text1">Box Qty</label>
                                            <input id="sellQuantity" name="sellQuantity" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.sellQuantity}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="details-form-field">
                                            <label for="width" className="lbl-text1">Width</label>
                                            <input id="width" name="width" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.width}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="details-form-field">
                                            <label for="length" className="lbl-text1">Length</label>
                                            <input id="length" name="length" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={productData.length}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="sellUnit" className="lbl-text1">Sell Unit</label>*/}
                                        {/*    <input id="sellUnit" name="sellUnit" type="text"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.sellUnit}*/}
                                        {/*    //onChange={handleChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="actWidth" className="lbl-text1">Actual Width (in)</label>*/}
                                        {/*    <input id="actWidth" name="actWidth" type="number"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.actWidth}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field">*/}
                                        {/*    <label for="actLength" className="lbl-text1">Actual Length (in)</label>*/}
                                        {/*    <input id="actLength" name="actLength" type="number"*/}
                                        {/*        className="inp-text"*/}
                                        {/*        onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*        value={productData.actLength}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field !pb-1">*/}
                                        {/*    <label className="lbl-text1">Width</label>*/}
                                        {/*    <div className="table-cell">*/}
                                        {/*        <span className="inline-block">*/}
                                        {/*            {formatNumberDecPlaces(propsProductData.widthFt, 3)}*/}
                                        {/*        </span>*/}
                                        {/*        <span className="inline-block pl-1 pr-4">*/}
                                        {/*            ft*/}
                                        {/*        </span>*/}
                                        {/*        <span className="inline-block">*/}
                                        {/*            {formatNumberDecPlaces(propsProductData.widthIn, 3)}*/}
                                        {/*        </span>*/}
                                        {/*        <span className="inline-block pl-1 pr-4">*/}
                                        {/*            in*/}
                                        {/*        </span>*/}
                                        {/*        <span>*/}
                                        {/*            (*/}
                                        {/*            {formatNumberDecPlaces(propsProductData.widthTotalFt, 3)}*/}
                                        {/*            <span className="pl-1">*/}
                                        {/*                ft*/}
                                        {/*            </span>*/}
                                        {/*            )*/}
                                        {/*        </span>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field !pb-1">*/}
                                        {/*    <label className="lbl-text1">Length</label>*/}
                                        {/*    <div className="table-cell">*/}
                                        {/*        <span className="inline-block">*/}
                                        {/*            {formatNumberDecPlaces(propsProductData.lengthFt, 3)}*/}
                                        {/*        </span>*/}
                                        {/*        <span className="inline-block pl-1 pr-4">*/}
                                        {/*            ft*/}
                                        {/*        </span>*/}
                                        {/*        <span className="inline-block">*/}
                                        {/*            {formatNumberDecPlaces(propsProductData.lengthIn, 3)}*/}
                                        {/*        </span>*/}
                                        {/*        <span className="inline-block pl-1 pr-4">*/}
                                        {/*            in*/}
                                        {/*        </span>*/}
                                        {/*        <span>*/}
                                        {/*            (*/}
                                        {/*            {formatNumberDecPlaces(propsProductData.lengthTotalFt, 3)}*/}
                                        {/*            <span className="pl-1">*/}
                                        {/*                ft*/}
                                        {/*            </span>*/}
                                        {/*            )*/}
                                        {/*        </span>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="details-form-field text-center">*/}
                                        {/*    <label for="isItem" className="lbl-cb w-1/3">*/}
                                        {/*        Item*/}
                                        {/*        <input id="isItem" name="isItem" type="checkbox"*/}
                                        {/*            className="inp-cb"*/}
                                        {/*            checked={productData.isItem}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            disabled*/}
                                        {/*        />*/}
                                        {/*    </label>*/}
                                        {/*    <label for="isRoll" className="lbl-cb w-1/3">*/}
                                        {/*        Roll*/}
                                        {/*        <input id="isRoll" name="isRoll" type="checkbox"*/}
                                        {/*            className="inp-cb"*/}
                                        {/*            checked={productData.isRoll}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            disabled*/}
                                        {/*        />*/}
                                        {/*    </label>                                            */}
                                        {/*</div>*/}

                                        <div className="details-form-field text-center pt-2">
                                            <label for="isPnw" className="lbl-cb w-1/3">
                                                PNW
                                                <input id="isPnw" name="isPnw" type="checkbox"
                                                    className="inp-cb"
                                                    checked={productData.isPnw}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            <label for="isAz" className="lbl-cb w-1/3">
                                                AZ
                                                <input id="isAz" name="isAz" type="checkbox"
                                                    className="inp-cb"
                                                    checked={productData.isAz}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            <label for="isWeb" className="lbl-cb w-1/3">
                                                Web
                                                <input id="isWeb" name="isWeb" type="checkbox"
                                                    className="inp-cb"
                                                    checked={productData.isWeb}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>

                                        <div className="details-form-field text-center pt-2">
                                            <label for="isStock" className="lbl-cb w-1/3">
                                                Stock
                                                <input id="isStock" name="isStock" type="checkbox"
                                                    className="inp-cb"
                                                    checked={productData.isStock}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            <label for="inActive" className="lbl-cb w-1/3">
                                                Inactive
                                                <input id="productInactive" name="productInactive" type="checkbox"
                                                    className="inp-cb"
                                                    checked={productData.productInactive}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            <label for="deleted" className="lbl-cb w-1/3">
                                                Deleted
                                                <input id="productDeleted" name="productDeleted" type="checkbox"
                                                    className="inp-cb"
                                                    checked={productData.productDeleted}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    {/*<div className="!w-[100%]">*/}
                                    {/*    <div className="details-form-field">*/}
                                    {/*        <label className="lbl-text1">Stores</label>*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <ul className="ul-prod-detail">*/}
                                    {/*            {storeList1}*/}
                                    {/*        </ul>*/}
                                    {/*        */}{/*<ul className="table-cell ul-prod-detail mr-4">*/}
                                    {/*        */}{/*    {storeList2}*/}
                                    {/*        */}{/*</ul>*/}
                                    {/*        */}{/*<ul className="table-cell ul-prod-detail">*/}
                                    {/*        */}{/*    {storeList3}*/}
                                    {/*        */}{/*</ul>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div className={"text-center h-[30px] pt-2 " + messageClass}>
                                {message}
                            </div>
                            <div className="w-full text-right pt-1">                                
                                <div className="inline-block">
                                    <button onClick={e => onCloseClick()} type="button" className="btn-cancel">
                                        Cancel
                                    </button>
                                    {productId === 0 &&
                                        <button type="button" className="btn-submit"
                                            onClick={(e) => btnSubmitClick(e, false)}
                                        >
                                            Save
                                        </button>
                                    }
                                    {productId > 0 &&
                                        <button type="button" className="btn-submit"
                                            onClick={(e) => btnSubmitClick(e, true)}>
                                            Save & Close
                                        </button>
                                    }                                    
                                </div>
                            </div>
                        </div>
                        <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
                    </div>
                }
            </div>
        </div>
    );
}