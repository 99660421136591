import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/apiService';

export function DepositForm({ onAddDeposit, onClose }) {
    const [formData, setFormData] = useState({
        StoreId: '',
        RegisterName: '',
        RegisterType: '',
        GLAccountCode: ''
    });
    const [stores, setStores] = useState([]);

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await get("/store/GetAllStores");
                console.log('Stores:', response);
                if (response && Array.isArray(response)) {
                    setStores(response);
                } else {
                    console.error('Expected an array for stores, received:', response);
                }
            } catch (error) {
                console.error('Error fetching stores', error);
            }
        };

        fetchStores();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(`Changing ${name} to:`, value);

        setFormData(prev => {
            let updatedValue = value;
            if (name === 'StoreId') {
                updatedValue = value === '' ? '' : parseInt(value, 10);
            }
            const updatedData = {
                ...prev,
                [name]: updatedValue
            };
            console.log('Updated form data:', updatedData);
            return updatedData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isNaN(formData.StoreId)) {
                throw new Error('Invalid Store ID');
            }

            const response = await post('/depositregisters/CreateDepositRegister', formData);
            onAddDeposit(response.data);
            onClose();
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Add New Deposit Register</h2>
            <div>
                <label htmlFor="StoreId" className="block text-sm font-medium text-gray-700">Store:</label>
                <select
                    id="StoreId"
                    name="StoreId"
                    value={formData.StoreId}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    <option value="">Select a store</option>
                    {stores.map(store => (
                        <option key={store.storeId || store.id} value={store.storeId || store.id}>
                            {store.name || store.displayName}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label htmlFor="RegisterName" className="block text-sm font-medium text-gray-700">Register Name:</label>
                <input
                    type="text"
                    id="RegisterName"
                    name="RegisterName"
                    value={formData.RegisterName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div>
                <label htmlFor="RegisterType" className="block text-sm font-medium text-gray-700">Register Type:</label>
                <select
                    id="RegisterType"
                    name="RegisterType"
                    value={formData.RegisterType}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    <option value="">Select Type</option>
                    <option value="Cash/Check">Cash/Check</option>
                    <option value="MC/Visa">MC/Visa</option>
                    <option value="AMEX">AMEX</option>
                </select>
            </div>
            <div>
                <label htmlFor="GLAccountCode" className="block text-sm font-medium text-gray-700">GL Account Code:</label>
                <input
                    type="text"
                    id="GLAccountCode"
                    name="GLAccountCode"
                    value={formData.GLAccountCode}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div className="flex justify-end space-x-2 mt-4">
                <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Submit
                </button>
            </div>
        </form>
    );
}