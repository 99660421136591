import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { VendorAutocomplete } from '../_reactHelpers/VendorAutocomplete';
import { isSelectedDropdown } from '../../js/formHelpers';

export const AddEditBillModal = ({ initialBillData, handleSubmit, stores, onHide }) => {

    const [localFormData, setLocalFormData] = useState(initialBillData || {
        storeId: '',
        vendorId: '',
        billDate: '',
        billStatus: 1,
        dueDate: '',
        poNumber: '',
        billNumber: '',
        billTotal: '',
        discRate: '',
        discAmt: '',
        nonDiscAmt: '',
        billLineItems: []
    });

    const [gridApi, setGridApi] = useState(null);
    const [vendorId, setVendorId] = useState('');

    const isEditMode = !!initialBillData;

    useEffect(() => {
        if (initialBillData) {
            console.log('Setting localFormData:', initialBillData);
            setLocalFormData(prevData => ({
                ...prevData,
                ...initialBillData,
                storeId: initialBillData.storeId?.toString() || '',
                vendorId: initialBillData.vendor ? initialBillData.vendor.vendorId?.toString() : initialBillData.vendorId?.toString()
            }));
        }
    }, [initialBillData]);

    const handleSelect = (selectedOption) => {
        setLocalFormData(prevData => ({
            ...prevData,
            vendorId: selectedOption.vendorId
        }));
        setVendorId(selectedOption.vendorId);
        console.log('Set Vendor ID:', selectedOption);
        //handleChange({
        //    name: 'vendorId',
        //    value: selectedOption.vendorId  
        //});
    };

    const handleChange = (event) => {
        let name, value, type, checked;
        //console.log('Event:', event);

        if (event.target) {
            ({ name, value, type, checked } = event.target);
        } else if (event.name && event.value !== undefined) {
            name = event.name;
            value = event.value;
            type = typeof value;
            checked = false;
        } else {
            console.error('Unexpected event format:', event);
            return;
        }

        let updatedValue = value;

        if (name === 'vendorId' || name === 'storeId') {
            updatedValue = value === '' ? '' : (typeof value === 'object' ? value.id : parseInt(value, 10));
            console.log('Vendor/Store ID set to:', updatedValue);                
        } else if (type === 'number' || (typeof value === 'string' && !isNaN(value))) {
            updatedValue = value === '' ? '' : Number(value);
        } else if (type === 'checkbox') {
            updatedValue = checked;
        }

        setLocalFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: updatedValue
            };

            if (name === 'billTotal' || name === 'nonDiscAmt' || name === 'discRate') {
                const billTotal = Number(name === 'billTotal' ? value : prevData.billTotal) || 0;
                const nonDiscAmt = Number(name === 'nonDiscAmt' ? value : prevData.nonDiscAmt) || 0;
                const discRate = Number(name === 'discRate' ? value : prevData.discRate) || 0;
                newData.discAmt = ((billTotal - nonDiscAmt) * discRate / 100).toFixed(2);
            }

            return newData;
        });
    };

    //const calculateProgress = () => {
    //    const requiredFields = ['storeId', 'vendorId', 'billDate', 'dueDate', 'poNumber', 'billTotal', 'billNumber', 'discRate', 'discAmt'];
    //    const filledRequiredFields = requiredFields.filter(field => localFormData[field] && localFormData[field].toString().trim() !== '').length;

    //    const lineItemFieldsCount = localFormData.billLineItems.length * 5;
    //    const filledLineItemFields = localFormData.billLineItems.reduce((count, item) => {
    //        return count + ['lineNumber', 'description', 'quantity', 'freight', 'total'].filter(field => item[field] && item[field].toString().trim() !== '').length;
    //    }, 0);

    //    const totalFields = requiredFields.length + lineItemFieldsCount;
    //    const filledFields = filledRequiredFields + filledLineItemFields;

    //    return (filledFields / totalFields) * 100;
    //};
    //const progress = calculateProgress();

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {

            if (!isSelectedDropdown(localFormData.billStatus)) {
                alert("Bill Status is required");
            }

            const formDataToSubmit = { ...localFormData };
            formDataToSubmit.vendorId = parseInt(vendorId, 10);
            formDataToSubmit.storeId = parseInt(formDataToSubmit.storeId, 10);

            ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt'].forEach(field => {
                formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
            });

            formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
                ...item,
                lineNumber: parseInt(item.lineNumber, 10),
                quantity: parseFloat(item.quantity),
                freight: parseFloat(item.freight),
                total: parseFloat(item.total),
                vendorId: parseInt(vendorId, 10)
            }));

            //const selectedStore = stores.find(store => store.storeId === formDataToSubmit.storeId);
            //formDataToSubmit.store = selectedStore;

            console.log('Submitting form data:', formDataToSubmit);

            // submit to parent page
            handleSubmit(formDataToSubmit);

        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleDeleteRow = useCallback((params) => {
        const updatedLineItems = localFormData.billLineItems.filter((item, index) => index !== params.node.rowIndex);
        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems]);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        editable: true,
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        sortable: false,
        resizable: true,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: ["no-border"]
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: 'lineNumber',
            headerName: 'Line #',
            maxWidth: 100,
            editable: false,
            type: 'number',
            valueFormatter: params => params.value ? Number(params.value).toFixed(0) : ''
        },
        {
            field: 'description',
            headerName: 'Description',
            editable: true
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            editable: true,
            type: 'number',
            valueFormatter: params => params.value ? Number(params.value).toFixed(2) : ''
        },
        {
            field: 'cost',
            headerName: 'Cost',
            editable: true,
            type: 'number',
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : ''
        },
        {
            field: 'freight',
            headerName: 'Freight',
            editable: true,
            type: 'number',
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : ''
        },
        {
            field: 'total',
            headerName: 'Total',
            editable: false,
            type: 'number',
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : ''
        },
        {
            headerName: '',
            field: 'lineNumber',
            maxWidth: 150,
            editable: false,
            cellRenderer: (params) => {
                return (
                    <button
                        onClick={() => handleDeleteRow(params)}
                        className="btn-cancel"
                        tabIndex="-1" // Prevent tab focus?
                    >
                        Delete
                    </button>
                );
            },
            suppressNavigable: true,
        }
    ], [handleDeleteRow]);


    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const onCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...localFormData.billLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        updatedRow[event.colDef.field] = event.newValue;

        // Calculate total
        const quantity = Number(updatedRow.quantity) || 0;
        const cost = Number(updatedRow.cost) || 0;
        const freight = Number(updatedRow.freight) || 0;
        updatedRow.total = (quantity * cost + freight).toFixed(2);

        updatedLineItems[event.rowIndex] = updatedRow;

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems, setLocalFormData]);


    const addNewRow = useCallback(() => {
        setLocalFormData(prevData => {
            const newLineNumber = (prevData.billLineItems?.length || 0) + 1;
            const newRow = {
                lineNumber: newLineNumber,
                description: '',
                quantity: 0,
                cost: 0,
                freight: 0,
                total: 0
            };
            return {
                ...prevData,
                billLineItems: [
                    ...(prevData.billLineItems || []),
                    newRow
                ]
            };
        });
    }, []);

    useEffect(() => {
        const updatedLineItems = localFormData.billLineItems.map(item => {
            const quantity = Number(item.quantity) || 0;
            const cost = Number(item.cost) || 0;
            const freight = Number(item.freight) || 0;
            return {
                ...item,
                total: (quantity * cost + freight).toFixed(2)
            };
        });

        if (JSON.stringify(updatedLineItems) !== JSON.stringify(localFormData.billLineItems)) {
            setLocalFormData(prevData => ({
                ...prevData,
                billLineItems: updatedLineItems
            }));
        }
    }, [localFormData.billLineItems]);

    return (
        <div className="p-4">
            <div className="max-h-[90vh]">

                <h2 className="text-2xl font-bold text-center">{isEditMode ? 'Edit Bill' : 'Add Bill'}</h2>
                <div className="overflow-y-auto flex-grow">

                    <form onSubmit={handleFormSubmit} className="space-y-4">
                        <h4 className="text-xl font-bold underline">Bill Details</h4>

                        {/*<div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">*/}
                        {/*    <div*/}
                        {/*        className={`h-2.5 rounded-full ${progress === 100 ? 'bg-green-600' : 'bg-blue-600'}`}*/}
                        {/*        style={{ width: `${progress}%` }}*/}
                        {/*    ></div>*/}
                        {/*</div>*/}

                        <div className="p-2">
                            <div className="grid grid-cols-1 grid-cols-4 gap-4 pr-2">

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store</span>
                                    </label>
                                    <select
                                        name="storeId"
                                        value={localFormData.storeId?.toString() || ''}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value="">Select a store</option>
                                        {stores.map((store) => (
                                            <option key={store.storeId} value={store.storeId.toString()}>
                                                {store.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Vendor</span>
                                    </label>
                                    <VendorAutocomplete
                                        name="vendorId"
                                        id="vendorId"
                                        onSelect={handleSelect}
                                        onChange={handleChange}
                                        initialVendorId={localFormData.vendorId}
                                        required
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="billDate"
                                        value={localFormData.billDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Due Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="dueDate"
                                        value={localFormData.dueDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 grid-cols-3 gap-4 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Purchase Order Number</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="PO Number"
                                        name="poNumber"
                                        value={localFormData.poNumber}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Number</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Bill Number"
                                        name="billNumber"
                                        value={localFormData.billNumber}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Status</span>
                                    </label>
                                    <select
                                        name="billStatus"
                                        value={localFormData.billStatus}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                    >
                                        <option value={0}>-- Select --</option>
                                        <option value={1}>Ready for Payment</option>
                                        <option value={2}>Payment Pending</option>
                                        <option value={3}>Paid</option>
                                        <option value={4}>Hold</option>
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 grid-cols-4 gap-4 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="Bill Total"
                                            name="billTotal"
                                            value={localFormData.billTotal}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    billTotal: formattedValue
                                                }));
                                            }}
                                            className="input input-bordered"
                                            required
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Non Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="Non Discount Amount"
                                            name="nonDiscAmt"
                                            value={localFormData.nonDiscAmt}
                                            onChange={handleChange}
                                            className="input input-bordered"
                                            required
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    nonDiscAmt: formattedValue
                                                }));
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Rate</span>
                                    </label>
                                    <label className="input-group">
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="Discount Rate"
                                            name="discRate"
                                            value={localFormData.discRate}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    discRate: formattedValue
                                                }));
                                            }}
                                            className="input input-bordered"
                                            required
                                        />
                                        <span>%</span>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="Discount Amount"
                                            name="discAmt"
                                            value={localFormData.discAmt}
                                            className="input input-bordered"
                                            readOnly
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="mt-8">
                                <h4 className="text-xl font-bold underline float-left">Line Item Details</h4>
                                <div className="float-right">
                                    <button
                                        type="button"
                                        onClick={addNewRow}
                                        className="btn-submit"
                                    >
                                        Add New Line Item
                                    </button>
                                </div>
                            </div>

                            <div className="ag-theme-alpine w-full clear pt-2" style={{ height: '250px' }}>
                                <AgGridReact
                                    rowData={localFormData.billLineItems || []}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    onGridReady={onGridReady}
                                    onCellValueChanged={onCellValueChanged}
                                    editType="fullRow"
                                    enterMovesDownAfterEdit={true}
                                    stopEditingWhenCellsLoseFocus={false}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                <div className="modal-action mt-6">
                    <button type="button" onClick={onHide} className="btn-cancel">Close</button>
                    <button type="submit" onClick={handleFormSubmit} className="btn-submit">
                        {isEditMode ? 'Update Bill' : 'Save Bill'}
                    </button>
                </div>
            </div>
        </div>
    );
}