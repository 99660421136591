import React, { Component } from 'react';
import { CheckPayments } from './CheckPayments';

export class PendingPayments extends Component {
    static displayName = PendingPayments.name;

    //constructor(props) {
    //    super(props);
    //}

    render() {
        return (
            <CheckPayments loadAchVendors={false}></CheckPayments>
        );
    }
}